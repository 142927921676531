import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor() {}

  getCurrentLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position) {
              const location = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              };
              resolve(location);
            }
          },
          (error) => console.log(error),
        );
      } else {
        reject('Geolocation is not supported by this browser.');
      }
    });
  }
}
