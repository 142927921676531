<section>
  <header
    class="d-flex align-items-center m-3 border-bottom pb-4"
    style="min-height: 100px"
  >
    <div class="container-fluid my-2">
      <div class="row justify-content-center">
        <div class="col-12">
          <h1
            class="display-1 fw-bold mb-4 text-uppercase"
            style="letter-spacing: -1px; max-width: 990px"
          >
            Un placer conocerte somo tix
          </h1>
          <p class="text-secoundary" style="max-width: 800px">
            Nacimos en el 2018 con la idea de revolucionar la forma en las que
            los dominicanos adquieren entradas para eventos y al mismo tiempo
            brindar tecnologia de vanguardia para facilitar el trabajo de los
            organizadores.
          </p>
          <p class="text-secoundary" style="max-width: 800px">
            Nos enorgullece ser una parte fundamental de la comunidad de
            eventos, brindando apoyo tanto a los organizadores como a los
            asistentes.
          </p>
        </div>
      </div>
    </div>
  </header>

  <div class="position-relative py-4">
    <div class="container-fluid h-100" style="max-width: 1020px">
      <div class="row h-100 align-items-center justify-content-between g-4">
        <div class="col-12">
          <h2 class="display-4 fw-bold text-center mb-md-4">
            Creemos fielmente que los eventos son capaces de cambiar vidas
          </h2>
        </div>
      </div>
    </div>
  </div>

  <div class="position-relative bg-black my-4">
    <img
      src="../../../../../assets/img/bg-about.png"
      class="position-absolute w-100 h-100 object-fit-cover z-0"
      style="filter: brightness(0.4)"
    />
    <div
      class="container-fluid position-relative py-5 z-3"
      style="max-width: 1020px"
    >
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 text-center text-white">
          <div
            class="d-grid mx-auto align-content-center"
            style="min-height: 300px; max-width: 690px"
          >
            <p>
              Ya sea una fiesta, un show de comedia, un festival de música o un
              taller, consideramos que en los eventos se crean historias que
              perduran. Es ahí donde conoces nuevos amigos, te diviertes,
              experimentas nuevas sensaciones y vives momentos mágicos.
            </p>
            <p>
              Sabemos lo importante que son estas experiencias, por eso creamos
              TIX.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="position-relative py-3 py-md-5">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-5 order-md-1">
          <h2 class="mb-4" style="line-height: 1rem">
            <span class="d-block display-4 fw-bold text-uppercase">
              Tix Founder
            </span>
            <strong class="text-uppercase fw-normal h6"> Raul Ruiz </strong>
          </h2>
          <p>
            Nacido en Miami y apasionado por el mundo del entretenimiento, la
            música y las artes visuales, siempre soñó con crear una plataforma
            que facilitara a las personas el acceso a los eventos más destacados
            y emocionantes del país. Una plataforma que fuera rápida, confiable
            y que revolucionara el mundo de la boletería en República
            Dominicana.
          </p>
          <p>
            Además, Raul Ruiz es un apasionado del entretenimiento. De hecho,
            creemos que tiene el récord Guinness de asitencia a la mayor
            cantidad de eventos en un solo año.
          </p>
        </div>
        <div class="col-12 col-md-5 order-md-0">
          <figure class="m-0 overflow-hidden rounded rounded-tix">
            <img
              src="../../../../../assets/img/bg-about.png"
              class="w-100 h-100 object-fit-cover"
              style="aspect-ratio: 3 / 3; object-position: center top"
              alt=""
            />
          </figure>
        </div>
      </div>
    </div>
  </div>

  <div class="position-relative py-3 py-md-5">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-5">
          <h2
            class="mb-4 display-4 fw-bold text-uppercase"
            style="line-height: 3.5rem"
          >
            El emocionante mundo de los eventos
          </h2>
          <p>
            A lo largo de nuestro crecimiento, hemos expandido nuestra oferta de
            servicios y mejoramos constantemente nuestra plataforma tecnológica
            para adaptarnos a las cambiantes necesidades de nuestros clientes.
          </p>
          <p>
            Estamos emocionados por lo que nos espera en el futuro y esperamos
            seguir siendo tu compañero confiable en el emocionante mundo de los
            eventos.
          </p>
        </div>
        <div class="col-12 col-md-5">
          <figure class="m-0 overflow-hidden rounded rounded-tix">
            <img
              src="../../../../../assets/img/bg-about-2.jpeg"
              class="w-100 h-100 object-fit-cover"
              style="aspect-ratio: 3 / 3; object-position: center top"
              alt=""
            />
          </figure>
        </div>
      </div>
    </div>
  </div>
</section>
