<section class="my-2">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 col-md col-lg-6 placeholder-glow">
        <div class="event-single-gallery">
          <div
            class="card-img-top rounded-tix placeholder col-12"
            style="height: 450px"
          ></div>
        </div>
      </div>
      <div class="col-12 col-md-auto col-lg-6 placeholder-glow">
        <article class="event-single-information">
          <h3 class="card-title h1 m-0 placeholder col-4 rounded-tix"></h3>
          <ul class="list-group my-3">
            <li class="p-0 border-0 aling-items-center d-flex text-muted mb-1">
              <p
                class="my-2 text-truncate h4 fw-normal placeholder col-6 rounded-tix"
              ></p>
            </li>
            <li class="p-0 border-0 aling-items-center d-flex text-muted mb-2">
              <p
                class="my-2 text-truncate h4 fw-normal placeholder col-6 rounded-tix"
              ></p>
            </li>
          </ul>

          <!-- Event tickets -->
          <div class="tickets mb-4">
            <ul class="list-group">
              <li
                class="aling-items-center justify-content-between d-flex bg-transparent py-2 px-0 border-bottom border-top-0 border-0 rounded-0"
              >
                <div class="col-4">
                  <ul class="list-group">
                    <li class="placeholder h4 col-6 rounded-tix mb-2"></li>
                    <li class="placeholder col-6 rounded-tix"></li>
                  </ul>
                </div>
                <div class="placeholder col-4 rounded-tix"></div>
              </li>
            </ul>
          </div>

          <!-- Event tickets -->

          <div class="form_buy-tickets">
            <div
              class="action_button btn btn-outline-dark rounded-pill w-100 fw-semibold placeholder col-8"
            ></div>
          </div>
        </article>
      </div>
    </div>
  </div>
</section>
