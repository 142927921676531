import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-skeleton',
  templateUrl: './order-skeleton.component.html',
  styleUrls: ['./order-skeleton.component.css'],
})
export class OrderSkeletonComponent implements OnInit {
  placeholders = Array(8).fill(0);

  constructor() {}

  ngOnInit(): void {}
}
