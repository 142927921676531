<section>
    <header class="d-flex align-items-center m-3" style="min-height: 100px;">
        <div class="container-fluid my-2">
            <div class="row justify-content-center">
                <div class="col-auto text-uppercase text-center">
                    <h1 class="display-1 fw-bold mb-1" style="letter-spacing: -1px;">Preguntas</h1>
                    <p class="text-secoundary" style="max-width: 800px;">"Hay una respuesta para todo"</p>
                </div>
            </div>
        </div>
    </header>
    <div class="position-relative py-3">
        <div class="container-fluid">
            <div class="row justify-content-between mb-5">
                <div class="col-12 col-md-4">
                   <h2 class="display-6 fw-bold mb-1 text-uppercase" style="letter-spacing: -1px;">Generales</h2>
                </div>
                <div class="col-12 col-md-8">
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item bg-transparent">
                            <h3 class="accordion-header bg-transparent">
                                <button class="accordion-button focus-ring focus-ring-dark text-dark px-0 bg-transparent text-uppercase fw-bold collapsed border-bottom border-dark" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne" aria-expanded="false"
                                    aria-controls="flush-collapseOne">
                                    Aún no he recibido mis entradas
                                </button>
                            </h3>
                            <div id="flush-collapseOne" class="accordion-collapse collapse px-0"
                                data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body px-0">Recuerda que las entradas son digitales, las recibirás en tu correo electrónico. Si verficaste tu correo y aún no la has recibido, puedes verificar tu bandeja de promociones o contactarnos a info@tix.do y con mucho gusto te la reenviaremos.</div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h3 class="accordion-header bg-transparent">
                                <button class="accordion-button focus-ring focus-ring-dark text-dark px-0 bg-transparent text-uppercase fw-bold collapsed border-bottom border-dark" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                    aria-controls="flush-collapseTwo">
                                    ¿Cómo hago para ingresar al evento?
                                </button>
                            </h3>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse px-0"
                                data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body px-0">Solo debes mostrar el Código QR de tu ticket en la entrada del evento para ser escaneado.</div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h3 class="accordion-header bg-transparent">
                                <button class="accordion-button focus-ring focus-ring-dark text-dark px-0 bg-transparent text-uppercase fw-bold collapsed border-bottom border-dark" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree" aria-expanded="false"
                                    aria-controls="flush-collapseThree">
                                    Reembolsos / Cambios
                                </button>
                            </h3>
                            <div id="flush-collapseThree" class="accordion-collapse collapse px-0"
                                data-bs-parent="#accordionFlushExample">
                              <div class="accordion-body px-0">Los reembolsos y cambios quedan a discreción del organizador del evento. En caso de solicitud de reembolso o devolución puedes contactar directamente al organizador, de todas formas <a href="/politicas-de-devolucion">aquí</a> puedes ver nuestras políticas con respecto a estos temas.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-12 col-md-4">
                   <h2 class="display-6 fw-bold mb-1 text-uppercase" style="letter-spacing: -1px;">Organizador de Eventos</h2>
                </div>
                <div class="col-12 col-md-8">
                    <div class="accordion accordion-flush" id="accordionFlushExampleDos">
                        <div class="accordion-item bg-transparent">
                            <h3 class="accordion-header bg-transparent">
                                <button class="accordion-button focus-ring focus-ring-dark text-dark px-0 bg-transparent text-uppercase fw-bold collapsed border-bottom border-dark" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOneDos" aria-expanded="false"
                                    aria-controls="flush-collapseOneDos">
                                    ¿Cómo publicar un evento?
                                </button>
                            </h3>
                            <div id="flush-collapseOneDos" class="accordion-collapse collapse px-0"
                                data-bs-parent="#accordionFlushExampleDos">
                                <div class="accordion-body px-0">
                                  <p>Para publicar tu evento solo debes crear una cuenta <a [href]="vendorRegisterUrl" target="_blank">aquí</a> y seguir los pasos.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h3 class="accordion-header bg-transparent">
                                <button class="accordion-button focus-ring focus-ring-dark text-dark px-0 bg-transparent text-uppercase fw-bold collapsed border-bottom border-dark" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwoDos" aria-expanded="false"
                                    aria-controls="flush-collapseTwoDos">
                                    Costos / Comisiones
                                </button>
                            </h3>
                            <div id="flush-collapseTwoDos" class="accordion-collapse collapse px-0"
                                data-bs-parent="#accordionFlushExampleDos">
                                <div class="accordion-body px-0">
                                    <p>
                                        Como organizador de eventos solo pagas un 4.9% + RD$25 por entrada vendida, si tu evento no vendió nada no pagas nada.
                                    </p>
                                    <p>
                                        Luego el participante de tu evento paga un cargo por servicio de 8.5% + RD$15 (Mínimo RD$80).
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h3 class="accordion-header bg-transparent">
                                <button class="accordion-button focus-ring focus-ring-dark text-dark px-0 bg-transparent text-uppercase fw-bold collapsed border-bottom border-dark" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThreeDos" aria-expanded="false"
                                    aria-controls="flush-collapseThreeDos">
                                    ¿Cuando recibo el pago de mi evento?
                                </button>
                            </h3>
                            <div id="flush-collapseThreeDos" class="accordion-collapse collapse px-0"
                                data-bs-parent="#accordionFlushExampleDos">
                                <div class="accordion-body px-0">
                                    <p>Dentro de las 48 horas luego de finalizado el evento, recibirás el pago total vía transferencia bancaria.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
