import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-play-video',
  templateUrl: './play-video.component.html',
  imports: [CommonModule],
  styleUrls: ['./play-video.component.css'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PlayVideoComponent implements OnInit {
  @Input()
  videoId: string = '';

  constructor() {}

  ngOnInit(): void {}
}
