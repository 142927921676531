import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseHttpService {
  protected constructor(protected http: HttpClient) {}

  protected getApiBaseUrl(): string {
    return environment.apiUrl;
  }
}
