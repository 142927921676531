<div class="col-12 col-md-10 mt-5">
  <div class="modal fade" tabindex="-1" id="purchaseAuthentication">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Autenticación Requerida</h5>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center">
            <div
              id="step-up-iframe-position"
              class="ms-2 me-auto row justify-content-center align-items-center"
            ></div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="purchaseAuthenticationBtnClose"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            hidden
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
