<section>
  <header class="d-flex align-items-center m-3" style="min-height: 100px">
    <div class="container-fluid my-2">
      <div class="row justify-content-center">
        <div class="col-auto text-uppercase text-center">
          <h1 class="display-1 fw-bold mb-1" style="letter-spacing: -1px">
            Publica tu Evento
          </h1>
          <p class="text-secoundary" style="max-width: 800px">...</p>
        </div>
      </div>
    </div>
  </header>
  <div class="position-relative py-3">
    <div class="container-fluid h-100" style="max-width: 1020px">
      <div class="row h-100 align-items-center justify-content-between">
        <div class="col-12 col-md-5">
          <h2 class="display-6 mb-4">Publica super fácil</h2>
          <p>
            Publicar tu evento en tix es super sencillo, solo tienes que crear
            una cuenta aquí y seguir los pasos, luego tu evento será aprobado y
            publicado por nuestro equipo, en menos de 30 minutos.
          </p>
          <p>
            Como organizador de eventos solo pagas un 4.9% + RD$25 por entrada
            vendida, luego el participante paga un cargo por servicio que
            dependerá del valor de la entrada. Aquí puedes ver la tabla de los
            cargos por servicios.
          </p>
        </div>
        <div class="col-12 col-md-auto">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/4GTOLVv4Ezg"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</section>
