import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-return-politics',
  templateUrl: './return-politics.component.html',
  styleUrls: ['./return-politics.component.css'],
})
export class ReturnPoliticsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
