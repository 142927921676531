import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import TicketType from '../../../core/models/ticket.type';
import { TicketStatus } from '../../../core/models/ticket.status';
import TicketVerificationDto from '../../../core/dtos/ticket-verification-dto';
import SendTicketVerificationDto from '../../../core/dtos/send-ticket-verification-dto';

export type ResponseTicketTypeOrGroupedByDate =
  | TicketType[]
  | { [day: string]: TicketType[] };

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  constructor(private http: HttpClient) {}

  getAvailableTickets(ticketsIds: number[]) {
    let arrayFilter = '';

    ticketsIds.forEach((ticket, index) => {
      arrayFilter += `&filters[id][$in][${index}]=${ticket}`;
    });

    return this.http
      .get(
        `${environment.apiUrl}/tickets?populate[0]=ticket_type&filters[status]=${TicketStatus.AVAILABLE}${arrayFilter}`,
      )
      .pipe(
        map((resp: any) => {
          const ticketsMapped: { label: string; id: number }[] = [];
          resp.data.forEach((_data: any) => {
            ticketsMapped.push({
              label: _data.attributes.label,
              id: _data.id,
            });
          });
          return ticketsMapped;
        }),
        catchError(() => of([])),
      );
  }

  validateTicketsAvailability(
    eventId: number,
    ticketsIds: SendTicketVerificationDto[],
  ): Observable<TicketVerificationDto[] | null> {
    let url = `${environment.apiUrl}/events/${eventId}/valta`;

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http
      .post(url, JSON.stringify({ data: ticketsIds }), options)
      .pipe(
        map((rs: any) => {
          if (rs.data.length == 0) return null;
          for (let i = 0; i < ticketsIds.length; i++) {
            const rsElement = rs.data[i];
            if (
              (rsElement.isSeat &&
                rsElement.tickets.length !== ticketsIds[i].tickets.length) ||
              rsElement.tickets.length !== ticketsIds[i].tickets.length
            )
              return null;
          }
          return rs.data;
        }),
        catchError(() => of(null)),
      );
  }

  getTicketType(
    eventId: number,
  ): Observable<ResponseTicketTypeOrGroupedByDate> {
    const currentDate = new Date();
    const dateString = currentDate.toISOString();
    const url = `${environment.apiUrl}/ticket-types?sort[0]=start_on:asc&statuses[0]=available&statuses[1]=on_hold&filters[event]=${eventId}&filters[$or][0][available_from][$null]=true&filters[$or][1][available_from][$lte]=${dateString}&filters[$or][1][available_to][$gte]=${dateString}`;

    return this.http.get(url).pipe(
      map((resp: any) => {
        const isGroupedByDate = !Array.isArray(resp.data);
        let data: ResponseTicketTypeOrGroupedByDate;

        if (isGroupedByDate) {
          data = parseGroupedByDate(resp.data);
        } else {
          data = parseTicketTypeList(resp.data);
        }

        return data;
      }),
      catchError(() => of([])),
    );
  }
}

function parseTicketTypeList(data: any) {
  const ticketsMapped: TicketType[] = [];

  data.forEach((_data: any) => {
    ticketsMapped.push(mappedTicket(_data));
  });

  return ticketsMapped;
}

function parseGroupedByDate(data: any) {
  const ticketsMappedByDay: { [day: string]: TicketType[] } = {};

  for (const day in data) {
    const ttsDay: TicketType[] = [];

    for (const _data of data[day]) {
      ttsDay.push(mappedTicket(_data));
    }

    ticketsMappedByDay[day] = ttsDay;
  }

  return ticketsMappedByDay;
}

function mappedTicket(_data: any) {
  return {
    active: _data.attributes.active,
    capacity: _data.attributes.capacity,
    id: _data.id,
    isSeat: _data.attributes.isSeat,
    isFree: _data.attributes.isFree,
    name: _data.attributes.name,
    price: _data.attributes.price,
    status: _data.attributes.status,
    tickets: _data.attributes.tickets,
    prefixes: _data.attributes.prefixes,
    max_tickets_per_order: _data.attributes.max_tickets_per_order,
    min_tickets_per_order: _data.attributes.min_tickets_per_order,
    place: _data.attributes.place,
    start_on: _data.attributes.start_on,
    end_on: _data.attributes.end_on,
    comment: _data.attributes.comment,
    link: _data.attributes.link,
  };
}
