<div
  [class]="{ show: visible, 'pe-none': !visible }"
  class="loading_container align-items-center bottom-0 d-flex justify-content-center left-0 position-fixed start-0 top-0 w-100"
  style="z-index: 9999"
>
  <div
    [class]="{ show: visible }"
    class="loading_content align-items-center bg-white d-flex flex-column justify-content-center rounded-5"
  >
    <img src="assets/img/icon-footer1.gif" class="w-50" alt="" />
    <p>{{ message }}</p>
  </div>
</div>
