<div class="add-ticket-type">
  <button
    (click)="openModal()"
    class="btn btn-outline-dark btn-sm px-3 fw-semibold rounded rounded-pill position-relative bg-white text-dark w-100"
    data-bs-toggle="modal"
    [attr.data-bs-target]="'#event_' + ticketType?.id"
  >
    Elegir asientos
  </button>
  <div
    class="ticket-type-seating small p-1 pe-3 bg-dark bg-opacity-10 rounded-bottom pt-3 d-flex align-items-center"
    [ngClass]="{ 'selected-seats': getTotalSeatsSelected() > 0 }"
  >
    <span class="material-symbols-outlined mx-1 small-icon">event_seat</span>
    <small class="fw-semibold">{{ getTotalSelectedLabel() }}</small>
  </div>
</div>

<!-- Modal ticket type -->
<div
  #fancyContainer
  class="modal fade"
  [attr.id]="'event_' + ticketType?.id"
  tabindex="-1"
  aria-labelledby="event_type_Label"
  aria-hidden="true"
  data-bs-keyboard="false"
  data-bs-backdrop="static"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
    [class.modal-xl]="eventMapImgSrc != ''"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h1
          class="modal-title fs-5 d-flex align-items-center"
          id="event_type_Label"
        >
          <span class="material-symbols-outlined mx-2" style="font-size: 26px"
            >event_seat</span
          >
          Elegir asientos
        </h1>
      </div>
      <div class="modal-body m-0 pt-0">
        <div *ngIf="eventMapImgSrc == ''">
          <div class="event-ticket-type ticket-type-information small py-3">
            <h2 class="h6 mb-1 pb-2 fw-bold text-uppercase">
              {{ ticketType?.name }}
            </h2>
            <data class="amount d-block small" value="{{ ticketType?.price }}"
              >RD${{ ticketType?.price | number }}</data
            >
            <small class="text-muted">{{ getAvailableLabel() }}</small>
          </div>
          <hr class="border-secondary p-0 m-0" />
          <div class="strong text-muted small mb-1 mt-3">
            Elige tus asientos
          </div>
          <ng-container *ngIf="ticketRows && ticketRows.length > 0">
            <ul
              *ngFor="let row of ticketRows"
              class="list-group position-relative"
            >
              <h6
                *ngIf="ticketsByRow(row)?.length"
                class="position-sticky sticky-top bg-white h6 mb-0 py-2 top-0 border-bottom"
              >
                Fila - {{ row.toUpperCase() }}
              </h6>
              <li
                class="list-group-item"
                *ngFor="let ticket of ticketsByRow(row); index as i"
              >
                <div class="form-check form-switch">
                  <!-- [disabled]="isTicketUsed(ticket)" -->
                  <input
                    [checked]="ticket.status == TicketStatus.USED"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    [id]="'seatLabel' + ticket.id"
                    (change)="onSeatToggle(ticket, $event)"
                  />
                  <label
                    class="form-check-label"
                    [for]="'seatLabel' + ticket.id"
                  >
                    {{ ticket.label }}
                  </label>
                </div>
              </li>
            </ul>
          </ng-container>
        </div>

        <div class="row justify-content-center" *ngIf="eventMapImgSrc != ''">
          <div #mapContainer class="col-12 col-lg-auto">
            <img
              data-fancybox
              [src]="getMapEventImageUrl(eventMapImgSrc)"
              alt=""
              class="w-100 h-auto position-sticky sticky-top object-fit-contain rounded-tix"
              style="top: 10px; max-height: 60vh; cursor: zoom-in"
            />
          </div>
          <div class="col-12 col-md" style="max-width: 460px">
            <div class="event-ticket-type ticket-type-information small py-3">
              <h2 class="h6 mb-1 pb-2 fw-bold text-uppercase">
                {{ ticketType?.name }}
              </h2>
              <data class="amount d-block small" value="{{ ticketType?.price }}"
                >RD${{ ticketType?.price | number }}</data
              >
              <small class="text-muted">{{ getAvailableLabel() }}</small>
            </div>
            <hr class="border-secondary p-0 m-0" />
            <div class="strong text-muted small mb-1 mt-3">
              Elige tus asientos
            </div>
            <ng-container *ngIf="ticketRows && ticketRows.length > 0">
              <ul
                *ngFor="let row of ticketRows"
                class="list-group position-relative"
              >
                <h6
                  *ngIf="ticketsByRow(row)?.length"
                  class="position-sticky sticky-top bg-white h6 mb-0 py-2 top-0 border-bottom"
                >
                  Fila - {{ row.toUpperCase() }}
                </h6>
                <li
                  class="list-group-item"
                  *ngFor="let ticket of ticketsByRow(row); index as i"
                >
                  <div class="form-check form-switch">
                    <input
                      [checked]="ticket.status == TicketStatus.USED"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      [id]="'seatLabel' + ticket.id"
                      (change)="onSeatToggle(ticket, $event)"
                    />
                    <label
                      class="form-check-label"
                      [for]="'seatLabel' + ticket.id"
                    >
                      {{ ticket.label }}
                    </label>
                  </div>
                </li>
              </ul>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button
          type="button"
          class="btn btn-dark rounded-pill ms-auto me-0 px-4"
          [attr.data-bs-dismiss]="minimumRequiredMissing ? undefined : 'modal'"
          (click)="bookSeats()"
        >
          Aceptar y continuar
        </button>
      </div>
    </div>
  </div>
</div>
