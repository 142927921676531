<div class="row g-2" (click)="goToEvent()">
  <div class="col">
    <img
      [src]="event?.coverUri"
      alt="cover uri"
      class="w-100 h-100 object-fit-cover rounded"
    />
  </div>
  <div class="col-10">
    <div class="fw-semibold">{{ event?.name }}</div>
    <div class="small">
      <data class="amount small me-1" *ngIf="event?.minPrice !== null">
        RD${{ event?.minPrice | number }}
      </data>
      <span class="small" *ngIf="event?.minPrice !== null">-</span>
      <data class="amount ms-1 small text-truncate">
        RD${{ event?.maxPrice | number }}
      </data>
    </div>
  </div>
</div>
