import { Routes } from '@angular/router';
import { ContactComponent } from './pages/contact/contact.component';
import { PriceComponent } from './pages/price/price.component';
import { EventPublishComponent } from './pages/event-publish/event-publish.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FaqComponent } from './pages/faq/faq.component';
import { OrderInsuranceComponent } from './pages/order-insurance/order-insurance.component';
import { UsageTermsComponent } from './pages/usage-terms/usage-terms.component';
import { ReturnPoliticsComponent } from './pages/return-politics/return-politics.component';
import { BrandComponent } from './pages/brand/brand.component';

export const routes: Routes = [
  { path: 'contacto', component: ContactComponent },
  { path: 'precios', component: PriceComponent },
  { path: 'publicar-evento', component: EventPublishComponent },
  { path: 'sobre-nosotros', component: AboutUsComponent },
  { path: 'preguntas-frecuentes', component: FaqComponent },
  { path: 'asegura-tu-compra', component: OrderInsuranceComponent },
  { path: 'terminos-de-uso', component: UsageTermsComponent },
  { path: 'politicas-de-devolucion', component: ReturnPoliticsComponent },
  { path: 'logos', component: BrandComponent },
];
