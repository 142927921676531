<section>
  <header class="d-flex align-items-center m-3" style="min-height: 100px">
    <div class="container-fluid my-2">
      <div class="row justify-content-center">
        <div class="col-auto text-uppercase text-center">
          <h1 class="display-1 fw-bold mb-1" style="letter-spacing: -1px">
            Reembolso
          </h1>
          <p class="text-secoundary" style="max-width: 800px">
            "Nuestras políticas de reembolso son como el horóscopo, a veces
            aplica a veces no"
          </p>
        </div>
      </div>
    </div>
  </header>
  <div class="position-relative py-3">
    <app-return-policies-content></app-return-policies-content>
  </div>
</section>
