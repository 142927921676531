import MobileOs from '../enums/mobile-os';

export default function getMobileOS() {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/windows phone/i.test(userAgent)) return MobileOs.Windows;

  if (/android/i.test(userAgent)) return MobileOs.Android;

  if (/iPad|iPhone|iPod/i.test(userAgent)) return MobileOs.IOS;

  return null;
}
