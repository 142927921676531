<div class="tickets mb-4">
  <ul class="list-group">
    <li
      *ngFor="let ticket of parsedTicketTypeList"
      class="list-group-item bg-transparent py-2 px-0 border-bottom border-top-0 border-0 rounded-0"
    >
      <div
        class="event-ticket-type d-flex align-items-center gap-4 justify-content-between"
      >
        <div class="event-ticket-type ticket-type-information">
          <h2 class="h6 mb-1 fw-bold text-uppercase">{{ ticket.name }}</h2>
          <data class="amount d-block small" value="">{{
            !ticket.price ? "GRATIS" : "RD$" + (ticket.price | number)
          }}</data>
        </div>

        <unavailable-button
          *ngIf="isUnavailable(ticket)"
          [soldOut]="isSoldOut(ticket)"
          [holdedOut]="isHoldedOut(ticket)"
        ></unavailable-button>

        <selector-button
          [ticketType]="ticket"
          *ngIf="isSelector(ticket)"
          (ticketEventEmitter)="onTicketChangeEvent.emit($event)"
        ></selector-button>

        <seat-picker-button
          [ticketType]="ticket"
          [eventMapImgSrc]="eventMapImgSrc"
          (ticketEventEmitter)="onTicketChangeEvent.emit($event)"
          *ngIf="isSeat(ticket)"
        ></seat-picker-button>
      </div>
    </li>
  </ul>

  <div
    class="accordion accordion-flush"
    id="accordionFlushExample"
    *ngIf="isGroupedByDate()"
  >
    <div class="accordion-item bg-transparent" *ngFor="let day of days">
      <h3 class="accordion-header bg-transparent">
        <button
          class="accordion-button focus-ring focus-ring-dark text-dark px-0 bg-transparent text-uppercase fw-bold collapsed border-bottom border-dark"
          type="button"
          data-bs-toggle="collapse"
          [attr.data-bs-target]="'#' + day.split(' ')[0]"
          aria-expanded="false"
          [attr.aria-controls]="'#' + day.split(' ')[0]"
        >
          {{ day }}
        </button>
      </h3>
      <div [id]="day.split(' ')[0]" class="accordion-collapse collapse px-0">
        <div class="accordion-body px-0">
          <ul class="list-group">
            <li
              *ngFor="let ticket of getGroupedByDayData(day)"
              class="list-group-item bg-transparent py-2 px-0 border-bottom border-top-0 border-0 rounded-0"
            >
              <div
                class="event-ticket-type d-flex align-items-center gap-4 justify-content-between"
              >
                <div class="event-ticket-type ticket-type-information">
                  <h2 class="h6 mb-1 fw-bold text-uppercase">
                    {{ ticket.name }}
                  </h2>
                  <data class="amount d-block small" value="">{{
                    !ticket.price ? "GRATIS" : "RD$" + (ticket.price | number)
                  }}</data>
                </div>

                <unavailable-button
                  *ngIf="isUnavailable(ticket)"
                  [soldOut]="isSoldOut(ticket)"
                  [holdedOut]="isHoldedOut(ticket)"
                ></unavailable-button>

                <selector-button
                  [ticketType]="ticket"
                  *ngIf="isSelector(ticket)"
                  (ticketEventEmitter)="onTicketChangeEvent.emit($event)"
                ></selector-button>

                <seat-picker-button
                  [ticketType]="ticket"
                  [eventMapImgSrc]="eventMapImgSrc"
                  (ticketEventEmitter)="onTicketChangeEvent.emit($event)"
                  *ngIf="isSeat(ticket)"
                ></seat-picker-button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
