import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkout.outlet',
  templateUrl: './checkout.outlet.component.html',
  styleUrls: ['./checkout.outlet.component.css'],
})
export class CheckoutOutletComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
