<section>
  <header class="d-flex align-items-center m-3" style="min-height: 100px">
    <div class="container-fluid my-2">
      <div class="row justify-content-center">
        <div class="col-auto text-uppercase text-center">
          <h1 class="display-1 fw-bold mb-1" style="letter-spacing: -1px">
            Logos
          </h1>
          <p class="text-secoundary" style="max-width: 800px">
            *Para descargar nuestro logo solo debes hacer click la imagen.
          </p>
        </div>
      </div>
    </div>
  </header>
  <div class="position-relative py-3">
    <div class="container-fluid" style="max-width: 990px">
      <div class="row">
        <div class="col-12">
          <ul class="list-group text-center w-100 list-group-horizontal">
            <li class="list-group-item w-50 p-4">
              <a
                download="Logo-TIX-F_negro.png"
                href="../../../../../assets/img/Logo-TIX-F_negro.png"
                title="Logo Negro"
              >
                <img
                  src="../../../../../assets/img/Logo-TIX-F_negro.png"
                  style="max-width: 200px"
                  alt="Logo Negro"
                  class="w-100"
                />
              </a>
              <p class="h3">Logo Negro</p>
            </li>
            <li class="list-group-item w-50 p-4 text-bg-dark">
              <a
                download="Logo-TIX-F_blanco.png"
                href="../../../../../assets/img/Logo-TIX-F_blanco.png"
                title="Logo Negro"
              >
                <img
                  src="../../../../../assets/img/Logo-TIX-F_blanco.png"
                  style="max-width: 200px"
                  alt="Logo Blanco"
                  class="w-100"
                />
              </a>
              <p class="h3">Logo Blanco</p>
            </li>
          </ul>
        </div>
        <div class="col-12">
          <p class="py-4 text-center">
            Si no descarga automáticamente haz click derecho en el logo y luego
            click en «Guardar Imagen» o «Descargar Imagen».
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
