import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usage-terms-modal',
  templateUrl: './usage-terms-modal.component.html',
  styleUrls: ['./usage-terms-modal.component.css'],
})
export class UsageTermsModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
