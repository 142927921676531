<div
  class="row z-2 bg-light filter-row aling-items-center justify-content-between mb-2"
>
  <div class="col">
    <nav class="category_nav">
      <ul
        class="nav d-flex gap-2 justify-content-center justify-content-md-start"
      >
        <li class="nav-item" *ngFor="let category of categories; index as i">
          <div
            class="btn-link rounded-pill btn nav-link link-dark px-3 py-2"
            [ngClass]="{ active: indexActive == i }"
            (click)="onClickCategory(i)"
          >
            {{ category.name }}
          </div>
        </li>
        <!-- <li class="nav-item"><div class="btn-link rounded-pill btn nav-link link-dark px-3 py-2">Más categorías</div></li> -->
      </ul>
    </nav>
  </div>
  <div class="col-auto d-none d-md-block">
    <div
      class="event-filter-search p-1 d-flex border border-dark rounded rounded-tix"
    >
      <input
        type="text"
        class="border-0 ps-3"
        placeholder="Buscar"
        (keyup)="onSearchEvent($event)"
      />
      <!--<button class="btn btn-sm btn-dark rounded rounded-tix pb-0 px-2">
                <span class="material-symbols-outlined" style="font-size: 22px;">search</span>
            </button>-->
    </div>
  </div>
</div>
