<core-loading [visible]="false"></core-loading>
<div class="main" id="event_single_page">
  <app-not-available
    *ngIf="!loading && event == null; else details"
    message="Este evento no está disponible"
  ></app-not-available>
  <ng-template #details>
    <app-detail-skeleton *ngIf="loading"></app-detail-skeleton>

    <section *ngIf="!loading" #detailSection class="my-2">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12 col-md col-lg-6">
            <div class="event-single-gallery">
              <!-- Event carousel -->
              <event-carousel
                [square]="true"
                [banners]="banners"
              ></event-carousel>
              <app-play-video
                *ngIf="event?.videoId"
                [videoId]="event!.videoId || ''"
                ideoplay="Mirar"
                videotitle="Mis hijos se burlan de mi español"
              >
              </app-play-video>
              <!-- Event carousel -->
            </div>
          </div>
          <div class="col-12 col-md-auto col-lg-6">
            <article class="event-single-information">
              <h1 class="h1 mt-4 mb-4">{{ event?.name }}</h1>

              <ul class="list-group mt-3 mb-4">
                <li
                  class="list-group-item bg-transparent px-0 py-0 pt-1 border-0 align-items-center d-flex mb-1"
                >
                  <span class="material-symbols-outlined me-2"
                    >calendar_month</span
                  >
                  <p class="my-2 text-truncate h6 fw-normal">
                    {{ soonerTTDate | dateFormat }}
                  </p>
                </li>
                <li
                  class="list-group-item bg-transparent px-0 py-0 pt-1 border-0 align-items-center d-flex"
                >
                  <span class="material-symbols-outlined me-2"
                    >location_on</span
                  >
                  <p class="my-2 text-truncate h6 fw-normal">
                    {{ event?.place }}
                  </p>
                </li>

                <li
                  class="list-group-item bg-transparent px-0 py-0 pt-1 border-0 align-items-center d-flex"
                  *ngIf="event?.mapImageUri !== null"
                >
                  <button
                    class="btn btn-outline-dark rounded rounded-pill mt-3 btn-sm px-3"
                    (click)="openMap()"
                  >
                    Ver mapa del evento
                  </button>
                </li>
              </ul>

              <!-- Event tickets -->

              <event-ticket
                [ticketTypeList]="ticketTypeList"
                [eventMapImgSrc]="event?.mapImageUri ?? ''"
                (onTicketChangeEvent)="onTicketChangedListener($event)"
              ></event-ticket>

              <!-- Event tickets -->

              <div class="form_buy-tickets">
                <button
                  class="d-flex justify-content-between align-items-center btn btn-dark rounded rounded-pill py-3 px-4 btn-lg w-100"
                  [disabled]="disabledToCheckout"
                  (click)="proceedToCheckout()"
                >
                  <label class="h6 m-0">Comprar ahora</label>
                  <ng-container *ngIf="proceeding">
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Loading...</span>
                  </ng-container>
                  <data
                    *ngIf="!proceeding && !event?.free"
                    class="amount-sumary h6 m-0 fw-normal"
                    value="0.00"
                    >RD${{ totalAmount | number }}</data
                  >
                </button>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>

    <!-- Event vendor -->
    <event-vendor [event]="event"></event-vendor>
    <!-- Event vendor -->
  </ng-template>
</div>
