<button
  #threeDsChallengeBtn
  data-bs-toggle="modal"
  data-bs-target="#challengeModal"
  class="btn p-0 py-2 pe-3 link-dark d-none"
>
  Desafío
</button>
<div
  class="modal fade"
  tabindex="-1"
  id="challengeModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
>
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Desafío 3DS</h5>
      </div>
      <div class="modal-body">
        <form
          #challengeForm
          target="frame"
          id="tdsChallengeForm"
          method="POST"
          [action]="formConfig.RedirectPostUrl"
        >
          <input type="hidden" name="creq" [value]="formConfig.CReq" />
          <input type="hidden" name="”TermUrl”" [value]="formConfig.TermUrl" />
        </form>
        <iframe
          class="h-100"
          src="about:blank"
          name="frame"
          width="100%"
          height="200px"
        >
        </iframe>
      </div>
    </div>
  </div>
</div>
