import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-return-policies-content',
  templateUrl: './return-policies-content.component.html',
  styleUrls: ['./return-policies-content.component.css'],
})
export class ReturnPoliciesContentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
