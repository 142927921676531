import { Injectable } from '@angular/core';
import { BaseHttpService } from '../../../core/services/base.http.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import OrderDetails, {
  EventData,
  TicketData,
} from '../../../core/models/order.details';
import Order from '../../../core/models/order';
import CouponType from '../../../core/enums/couponType.enum';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends BaseHttpService {
  constructor(http: HttpClient) {
    super(http);
  }

  getDetail(slug: string): Observable<OrderDetails | null> {
    const url = `${this.getApiBaseUrl()}/orders
?filters[slug]=${slug}
&fields[0]=createdAt
&fields[1]=buyer_full_name
&fields[2]=buyer_email
&fields[3]=status
&fields[4]=insurance_fee
&fields[5]=service_fee
&fields[6]=total
&fields[7]=free_event
&fields[8]=sub_total
&populate[discounts][populate][coupon]=true
&populate[cancelled_tickets][populate][ticket_type]=true
&populate[discount_ticket_types][fields][0]=id
&populate[event][fields][0]=name
&populate[event][fields][1]=start_at
&populate[event][fields][0]=name
&populate[event][fields][1]=place
&populate[event][fields][2]=start_at
&populate[event][fields][3]=link
&populate[event][fields][4]=tickets_comments
&populate[tickets][fields][1]=label
&populate[tickets][fields][2]=check_in_at
&populate[tickets][fields][3]=price
&populate[tickets][populate][ticket_type][fields]=%2A
&populate[tickets][populate][gift][fields][0]=name
&populate[tickets][populate][ticket_type][fields][1]=isSeat
&populate[payment_history][fields][0]=card
&populate[payment_history][fields][1]=auth_code
&populate[payment_history][fields][2]=card_type`;

    return this.http.get(url).pipe(
      map((resp: any) => {
        resp.data = resp.data[0];

        const _order = resp.data.attributes;

        const _event: EventData = {
          id: _order.event.id,
          name: _order.event.data.attributes.name,
          ticketsComments: _order.event.data.attributes.tickets_comments,
          startAt: _order.event.data.attributes.start_at,
          place: _order.event.data.attributes.place,
          link: _order.event.data.attributes.link,
        };

        const order = {
          id: resp.data.id,
          buyerName: _order.buyer_full_name,
          buyerEmail: _order.buyer_email,
          createdAt: _order.createdAt,
          slug: _order.slug,
          event: _event,
          tickets: this.parseTickets(_order.tickets),
          cancelledTickets: this.parseTickets(_order.cancelled_tickets),
          status: _order.status,
          insurance_fee: _order.insurance_fee,
          subTotal: 0,
          free_event: _order.free_event,
          serviceFee: _order.service_fee,
          total: _order.total,
          sub_total: _order.sub_total,
          discounts: this.parseDiscounts(_order.discounts?.data),
          authorization: _order.payment_history?.data
            ? _order.payment_history?.data.attributes.auth_code
            : '',
          creditCard: _order.payment_history?.data
            ? _order.payment_history?.data.attributes.card
            : '',
          cardType: _order.payment_history?.data
            ? _order.payment_history?.data.attributes.card_type
            : '',
        };

        return order;
      }),
      catchError((e) => {
        console.log(e);
        return of(null);
      }),
    );
  }

  private parseDiscounts(discounts: any[]) {
    if (!discounts) {
      return [];
    }
    return discounts.map((d) => {
      const parsedDiscount = {
        discount: d.attributes.discount,
        discount_code: d.attributes.discount_code,
        discounted: d.attributes.discounted,
      } as any;

      if (d.attributes.coupon?.data) {
        parsedDiscount.coupon = d.attributes.coupon.data.attributes;
      }

      return parsedDiscount;
    });
  }

  private parseTickets(tickets: any) {
    const result: TicketData[] = [];

    tickets.data.forEach((ticket: any) => {
      result.push({
        id: ticket.id,
        label: ticket.attributes.label,
        checkInDate: ticket.attributes.check_in_at,
        ticketType: ticket.attributes.ticket_type,
        price: ticket.attributes.price,
        gift: ticket.attributes?.gift?.data,
        isSeat: ticket.attributes.ticket_type.data.attributes.isSeat,
      });
    });

    return result;
  }

  codeCouponsDiscount(order: Order | null) {
    let result = 0;

    if (!order?.coupons) return result;

    let couponDiscount = 0;
    let ticketTypesDiscount = 0;

    order.coupons.forEach((c) => {
      if (c.type == CouponType.CreditCard) return;

      if (c.ticket_types?.length) {
        let totalSum = 0;

        c.ticket_types.forEach((tt) => {
          const ticket = order!.tickets.find((t) => t.id === tt.id);

          if (ticket) {
            const priceSum = ticket.tickets[0].price * ticket.tickets.length;

            totalSum += priceSum;
          }
        });

        ticketTypesDiscount += totalSum * (c.discount / 100);
        c.discounted = ticketTypesDiscount;
      } else {
        if (c.discount > 100) {
          couponDiscount += 1;
        } else {
          couponDiscount += c.discount / 100;
        }

        c.discounted = (c.discount / 100) * order.subTotal;
      }
    });

    result = Math.abs(order.subTotal * couponDiscount - ticketTypesDiscount);
    if (result > order.subTotal) result = order.subTotal;

    return result;
  }

  getClientAddress() {
    return this.http.get('https://geolocation-db.com/json/');
  }
}
