export enum CybersourceThreeDSStatus {
  SUCCESS = 'AUTHENTICATION_SUCCESSFUL',
  PENDING = 'PENDING_AUTHENTICATION',
  FAILED = 'AUTHENTICATION_FAILED',
  INVALID = 'INVALID_REQUEST',
  AUTHORIZED = 'AUTHORIZED',
  PARTIAL_AUTHORIZED = 'PARTIAL_AUTHORIZED',
  AUTHORIZED_PENDING_REVIEW = 'AUTHORIZED_PENDING_REVIEW',
  AUTHORIZED_RISK_DECLINED = 'AUTHORIZED_RISK_DECLINED',
  PENDING_AUTHENTICATION = 'PENDING_AUTHENTICATION',
  PENDING_REVIEW = 'PENDING_REVIEW',
  DECLINED = 'DECLINED',
  INVALID_REQUEST = 'INVALID_REQUEST',
  MISSING_FIELD = 'MISSING_FIELD',
  INVALID_DATA = 'INVALID_DATA',
  DUPLICATE_REQUEST = 'DUPLICATE_REQUEST',
  INVALID_CARD = 'INVALID_CARD',
  CARD_TYPE_NOT_ACCEPTED = 'CARD_TYPE_NOT_ACCEPTED',
  INVALID_MERCHANT_CONFIGURATION = 'INVALID_MERCHANT_CONFIGURATION',
  PROCESSOR_UNAVAILABLE = 'PROCESSOR_UNAVAILABLE',
  INVALID_AMOUNT = 'INVALID_AMOUNT',
  INVALID_CARD_TYPE = 'INVALID_CARD_TYPE',
  INVALID_PAYMENT_ID = 'INVALID_PAYMENT_ID',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  SERVER_TIMEOUT = 'SERVER_TIMEOUT',
  SERVICE_TIMEOUT = 'SERVICE_TIMEOUT',
  INVALID_ACCOUNT_NOT_ALLOWED_CREDIT_PAYMENT_ID = 'INVALIDACCOUNT_NOT_ALLOWED_CREDIT_PAYMENT_ID',
  CANCELLED = 'CANCELLED',
  INSUFFICIENT_FUND = 'INSUFFICIENT_FUND',
}
