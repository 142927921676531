import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderDetailComponent } from './pages/order-detail/order-detail.component';
import { OrderDetailHeadbandComponent } from './components/order-detail-headband/order-detail-headband.component';
import { OrderDetailTicketsComponent } from './components/order-detail-tickets/order-detail-tickets.component';
import { OrderTicketsComponent } from './pages/order-tickets/order-tickets.component';
import { OrderTicketComponent } from './components/order-ticket/order-ticket.component';
import { CoreModule } from '../../core/core.module';
import { QRCodeModule } from 'angularx-qrcode';
import { OrderSkeletonComponent } from './pages/order-detail/order-skeleton/order-skeleton.component';
import { ShareModule } from '../../share/share.module';

@NgModule({
  declarations: [
    OrderDetailComponent,
    OrderDetailHeadbandComponent,
    OrderDetailTicketsComponent,
    OrderTicketsComponent,
    OrderTicketComponent,
    OrderSkeletonComponent,
  ],
  imports: [CommonModule, CoreModule, QRCodeModule, ShareModule],
})
export class OrderModule {}
