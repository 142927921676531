import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxMaskModule } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { EventsModule } from './features/events/events.module';
import { HttpClientModule } from '@angular/common/http';
import { OrderModule } from './features/order/order.module';
import { ContentModule } from './features/content/content.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    EventsModule,
    OrderModule,
    ContentModule,
    RouterModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
