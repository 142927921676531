import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { TicketStatus } from '../../../../../core/models/ticket.status';
import TicketType from '../../../../../core/models/ticket.type';
import Ticket from '../../../../../core/models/ticket';
import { UtilService } from '../../../../../core/services/util.service';
import { Fancybox } from '@fancyapps/ui';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'seat-picker-button',
  templateUrl: './seat-picker-button.component.html',
  styleUrls: ['./seat-picker-button.component.css'],
})
export class SeatPickerButtonComponent implements AfterViewInit, OnDestroy {
  @Input() eventMapImgSrc: string = '';
  @Input() ticketType: TicketType | null = null;
  @Output() ticketEventEmitter = new EventEmitter<TicketType>();

  @ViewChild('mapContainer') mapContainer!: ElementRef<HTMLElement>;
  @ViewChild('fancyContainer') fancyContainer!: ElementRef<HTMLElement>;

  usedTickets = 0;

  get ticketRows() {
    const rows = this.ticketType?.prefixes.split(',');

    return rows;
  }

  constructor(
    private utilService: UtilService,
    private meta: Meta,
  ) {}

  ngAfterViewInit() {
    if (this.mapContainer) {
      Fancybox.bind(this.mapContainer.nativeElement, '[data-fancybox]', {
        parentEl: this.fancyContainer.nativeElement,
        Images: {
          initialSize: 'max',
        },
      });
    }
  }

  ngOnDestroy() {
    if (this.mapContainer) {
      Fancybox.unbind(this.mapContainer.nativeElement);
      Fancybox.close();
    }
  }

  get minimumRequiredMissing() {
    return (
      this.usedTickets < this.ticketType!.min_tickets_per_order &&
      this.usedTickets > 0
    );
  }

  public bookSeats(): void {
    if (this.minimumRequiredMissing) {
      alert(
        'Mínimo de tickets por orden: ' +
          this.ticketType!.min_tickets_per_order,
      );
      return;
    }

    this.meta.updateTag({
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0',
    });

    if (this.ticketType) this.ticketEventEmitter.emit(this.ticketType);
  }

  getAvailableLabel(): string {
    if (this.ticketType) {
      const availableTotal = this.ticketType.tickets.length;

      const label = this.ticketType.isSeat
        ? availableTotal > 1
          ? 'asientos'
          : 'asiento'
        : availableTotal > 1
          ? 'tickets'
          : 'ticket';

      const availableLabel = availableTotal > 1 ? 'disponibles' : 'disponible';

      return `${availableTotal} ${label} ${availableLabel}`;
    }

    return '';
  }

  getTotalSelectedLabel(): string {
    if (this.ticketType) {
      const total = this.getTotalSeatsSelected();

      return total === 1 ? `${total} elegido` : `${total} elegidos`;
    }

    return '';
  }

  getTotalSeatsSelected(): number {
    return (
      this.ticketType?.tickets?.filter((t) => t.status === TicketStatus.USED)
        .length ?? 0
    );
  }

  onSeatToggle(ticket: Ticket, $event: Event): void {
    const target = $event.target as HTMLInputElement;

    if (
      this.usedTickets == this.ticketType!.max_tickets_per_order &&
      target.checked
    ) {
      alert(
        'Tickets máximos por orden: ' + this.ticketType!.max_tickets_per_order,
      );
      target.checked = false;
    }

    if (ticket.status === TicketStatus.USED) {
      this.usedTickets -= 1;

      ticket.status = TicketStatus.AVAILABLE;
    } else if (
      this.usedTickets < this.ticketType!.max_tickets_per_order &&
      ticket.status === TicketStatus.AVAILABLE
    ) {
      this.usedTickets += 1;

      ticket.status = TicketStatus.USED;
    }
  }

  isTicketUsed(ticket: Ticket): boolean {
    return ticket.status === TicketStatus.USED;
  }

  ticketsByRow(row: string) {
    //FILTERS THE TICKETS THAT MATCH THE ROW NAME HAVING A FORMAT OF [row_name][number]
    //WE USE TO AVOID ROWS LIKE 'B' AND 'BI' WHERE BI ROW TICKETS COULD MATCH B TICKETS IF WE ONLY USE AN INCLUDES
    const tickets = this.ticketType?.tickets.filter((ticket: Ticket) => {
      const regex = new RegExp(`\\b${row}\\d+\\b`);

      return regex.test(ticket.label);
    });

    const sortedTickets = tickets
      ? this.sortTicketsByLabel(tickets, row)
      : tickets;

    return sortedTickets;
  }

  getMapEventImageUrl(mapImage: string): string {
    return this.utilService.getAbsoluteUrl(mapImage);
  }

  sortTicketsByLabel = (tickets: any[], row: string) => {
    const result = tickets.sort((ticketA: any, ticketB: any) => {
      const aNumber = parseInt(ticketA.label.replace(row, '').trim());
      const bNumber = parseInt(ticketB.label.replace(row, '').trim());

      if (aNumber > bNumber) return 1;

      return -1;
    });

    return result;
  };
  protected readonly TicketStatus = TicketStatus;

  openModal() {
    this.meta.updateTag({
      name: 'viewport',
      content:
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no',
    });
  }
}
