<a
  class="text-dark z-1 link-card-event"
  [routerLink]="getEventUrl()"
  style="cursor: pointer"
>
  <div class="card card-event border-dark border-0 rounded-tix">
    <img [src]="getImageUrl()" class="card-img-top rounded-tix" alt="..." />
    <div
      class="card-body border rounded position-relative border-dark rounded-tix"
    >
      <div class="card-price d-flex mb-3 aling-items-center">
        <div
          class="d-flex bg-black text-white rounded-pill ps-1 py-1 pe-2 me-2"
          *ngIf="event?.hasAdd"
        >
          <span class="material-symbols-outlined me-md-1">ads_click</span>
          <span class="d-none d-md-block">Ad</span>
        </div>
        <ng-container *ngIf="event?.free; else eventPrices">
          <data class="amount me-1 small">GRATIS</data>
        </ng-container>
        <ng-template #eventPrices>
          <data
            class="amount me-1 small"
            *ngIf="
              event?.minPrice !== null && event?.minPrice !== event?.maxPrice
            "
            >RD${{ event?.minPrice | number }}</data
          >
          <span
            class="small"
            *ngIf="
              event?.minPrice !== null && event?.minPrice !== event?.maxPrice
            "
            >-</span
          >
          <data class="amount ms-1 small text-truncate"
            >RD${{ event?.maxPrice | number }}</data
          >
        </ng-template>
      </div>

      <h3 class="card-title h6 m-0">{{ event?.name }}</h3>

      <ul class="list-group my-3">
        <li
          class="list-group-item p-0 border-0 aling-items-center d-flex text-muted"
        >
          <span class="material-symbols-outlined me-1 mb-1 text-muted"
            >calendar_month</span
          >
          <small class="text-truncate">{{ event?.startAt | dateFormat }}</small>
        </li>
        <li
          class="list-group-item p-0 border-0 aling-items-center d-flex text-muted"
        >
          <span class="material-symbols-outlined me-1 mb-1 text-muted"
            >location_on</span
          >
          <small class="text-truncate">{{ event?.place }}</small>
        </li>
      </ul>
      <div
        class="action_button btn btn-outline-dark rounded-pill w-100 fw-semibold"
      >
        Tickets
      </div>
    </div>
  </div>
</a>
