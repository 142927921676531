import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import VendorMessageDto from '../../../core/dtos/vendor-message.dto';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  constructor(private http: HttpClient) {}

  sendMessageToVendor(vendorId: number, message: VendorMessageDto) {
    return this.http.post(
      `${environment.apiUrl}/vendor-accounts/send-message/${vendorId}`,
      message,
    );
  }
}
