<footer class="bg-black text-white position-relative mt-4 m-2 m-md-3 rounded rounded-tix p-3 p-md-4">
  <div class="container-fluid">

    <div class="row justify-content-between">

      <div class="col">
        <p class="h6 mb-3 mt-4 fw-light">Publica</p>
        <nav class="nav flex-column small mb-4 opacity-75">
          <a class="nav-link text-light py-1 fw-light ps-0" href="/precios">Precios</a>
          <a class="nav-link text-light py-1 fw-light ps-0" [href]="environment.vendorsUrl">Publica tu evento</a>
          <a class="nav-link text-light py-1 fw-light ps-0" href="/preguntas-frecuentes">Preguntas frecuentes</a>
          <a [href]="environment.vendorsUrl" class="nav-link text-light py-1 fw-light ps-0">Mi cuenta</a>
        </nav>
      </div>
      <div class="col">
        <p class="h6 mb-3 mt-4 fw-light">Nosotros</p>
        <nav class="nav flex-column small mb-4 opacity-75">
          <a class="nav-link text-light py-1 fw-light ps-0" href="/sobre-nosotros">Sobre nosotros</a>
          <a class="nav-link text-light py-1 fw-light ps-0" href="/logos">Descarga nuestro logo</a>
          <a class="nav-link text-light py-1 fw-light ps-0" href="/contacto">Contáctanos</a>
        </nav>
      </div>
      <div class="col">
        <p class="h6 mb-3 mt-4 fw-light">Información</p>
        <nav class="nav flex-column small mb-4 opacity-75">
          <a class="nav-link text-light py-1 fw-light ps-0" href="/asegura-tu-compra">Asegura tu compra</a>
          <a class="nav-link text-light py-1 fw-light ps-0" href="/terminos-de-uso">Términos de uso</a>
          <a class="nav-link text-light py-1 fw-light ps-0" href="/politicas-de-devolucion">Reembolso</a>
        </nav>
      </div>
      <div class="col-12 col-lg-auto">
        <p class="h6 mb-3 mt-4 fw-light">Contacto</p>
        <nav class="nav flex-column small mb-4 opacity-75">
          <a class="nav-link text-light py-1 fw-light ps-0" href="mailto:info@tix.do" target="_blank">info@tix.do</a>
          <a class="nav-link text-light py-1 fw-light ps-0 disable">Virgilio Diaz Ordoñez 201, Santo Domingo, R.D.</a>
          <a class="nav-link text-light py-1 fw-light ps-0 disable">Somos Carbon-Neutral ♻️</a>
        </nav>
      </div>
    </div>
    <div class="row justify-content-between align-items-center">
      <div class="col-auto">
        <small class="text-light opacity-75 d-block">© 2023 tix.do | Todos los derechos reservados</small>
      </div>
      <div class="col-auto">
        <figure class="opacity-75" style="width: 450px; height: auto; max-width: 100%">
          <img src="/assets/img/azul-brands.png" alt="azul_brands" style="width: 100%; height: auto">
        </figure>
      </div>
    </div>
  </div>
</footer>
