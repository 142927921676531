import { Pipe, PipeTransform } from '@angular/core';
import Event from '../../../core/models/event';

@Pipe({
  name: 'filterByCategory',
})
export class FilterByCategoryPipe implements PipeTransform {
  transform(eventList: Event[], category: string = 'Todos'): Event[] {
    return category === 'Todos'
      ? eventList
      : eventList.filter((event) => event.category.name === category);
  }
}
