import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import Banner from '../../../../core/models/banner';
import { UtilService } from '../../../../core/services/util.service';

@Component({
  selector: 'event-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
})
export class CarouselComponent implements AfterViewInit, OnDestroy {
  @ViewChildren('indicator') indicators!: QueryList<ElementRef>;
  @ViewChildren('item') items!: QueryList<ElementRef>;
  @ViewChild('next') nextButton!: ElementRef;
  @ViewChild('prev') prevButton!: ElementRef;

  @Input() square = false;

  @Input() sectionId?: string;
  @Input() banners: Banner[] = [];

  activeBanner = 0;
  cycleInterval: NodeJS.Timer | undefined;

  get mobile() {
    return screen.width < 768;
  }

  constructor(private utilService: UtilService) {}

  ngAfterViewInit() {
    this.initCycle();
  }

  ngOnDestroy() {
    clearInterval(this.cycleInterval as any);
  }

  initCycle() {
    this.cycleInterval = setInterval(() => {
      this.nextButton?.nativeElement.click();
    }, 4000);
  }

  onClickBanner(banner: Banner): void {
    if (banner.link !== null) {
      window.open(banner.link, '_blank');
    }
  }

  getBannerAbsoluteUrl(url: string): string {
    return this.utilService.getAbsoluteUrl(url);
  }

  handleMouseEnter() {
    clearInterval(this.cycleInterval as any);
  }

  handleMouseLeave() {
    this.initCycle();
  }
}
