<section>
  <header class="d-flex align-items-center m-3" style="min-height: 100px">
    <div class="container-fluid my-2">
      <div class="row justify-content-center">
        <div class="col-auto text-uppercase text-center">
          <h1 class="display-1 fw-bold mb-1" style="letter-spacing: -1px">
            Términos de uso
          </h1>
          <p class="text-secoundary" style="max-width: 800px">
            Con la compra de esta boleta o invitación electrónica el titular
            acepta voluntariamente cada una de las siguientes cláusulas:
          </p>
        </div>
      </div>
    </div>
  </header>
  <div class="position-relative py-3">
    <app-usage-terms-content></app-usage-terms-content>
  </div>
</section>
