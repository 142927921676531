import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import VendorMessageDto from '../../../../core/dtos/vendor-message.dto';
import { LoadingComponent } from '../../../../core/components/loading/loading.component';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  @ViewChild(LoadingComponent) loadingComponent!: LoadingComponent;

  sendMessageForm = this.fb.group({
    fullName: ['', Validators.required],
    email: ['', [Validators.email, Validators.required]],
    phone: ['', Validators.required],
    subject: ['', Validators.required],
    comment: ['', Validators.required],
  });
  constructor(
    private fb: FormBuilder,
    private contactService: ContactService,
  ) {}

  ngOnInit(): void {}

  get emailInvalid() {
    const control = this.sendMessageForm.controls.email;

    const result = control.errors && control.errors['email'];

    return result;
  }

  handleSubmit() {
    this.loadingComponent.toggle();

    const body = this.sendMessageForm.value as VendorMessageDto;

    this.contactService.sendMessage(body).subscribe({
      next: (response) => {
        setTimeout(() => {
          this.loadingComponent.toggle();
          alert('Mensaje enviado!');
        }, 700);
      },
      error: (err) => {
        this.loadingComponent.toggle();
        alert('No se pudo enviar el mensaje, intente más tarde.');
      },
    });
  }
}
