import { OrderCreationError } from '../enums/orderCreationError.enum';

export function getOrderCreationErrorMessage(error?: OrderCreationError) {
  switch (error) {
    case OrderCreationError.NO_TICKETS:
      return {
        response: 'La orden no contiene tickets.',
        recommendation: 'Intente Recargar la pagina',
      };
    default:
      return {
        response: 'No se pudo crear la orden.',
        recommendation:
          'intente nuevamente con otra tarjeta o contacte a su banco',
      };
  }
}
