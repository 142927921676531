import { AfterViewInit, Component, Input } from '@angular/core';

@Component({
  selector: 'core-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
})
export class LoadingComponent implements AfterViewInit {
  @Input() visible = false;
  @Input() message: string = 'Cargando...'; // Default message

  private body?: HTMLElement;
  private toggleButton?: HTMLElement;
  private closeButton?: HTMLElement;

  ngAfterViewInit(): void {
    this.toggleButton = document.getElementById('toggle_button') as HTMLElement;
    this.closeButton = document.getElementById('close_loading') as HTMLElement;
    this.body = document.getElementsByTagName('body')[0];
  }

  toggle(message?: string): void {
    if (message) this.message = message;

    this.visible = !this.visible;

    if (this.visible) {
      this.body?.classList.add('overflow-hidden');
    } else {
      this.body?.classList.remove('overflow-hidden');
    }
  }

  isVisible(): boolean {
    return this.visible;
  }
}
