import { Injectable } from '@angular/core';
import { Constants } from '../utils/constants';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  public get(
    key: string,
    storageType: string = Constants.StorageType.Local,
  ): string | null {
    return storageType === Constants.StorageType.Local
      ? localStorage.getItem(key)
      : sessionStorage.getItem(key);
  }

  public set(
    key: string,
    value: string,
    storageType: string = Constants.StorageType.Local,
  ): void {
    storageType === Constants.StorageType.Local
      ? localStorage.setItem(key, value)
      : sessionStorage.setItem(key, value);
  }

  public remove(
    key: string,
    storageType: string = Constants.StorageType.Local,
  ): void {
    storageType === Constants.StorageType.Local
      ? localStorage.removeItem(key)
      : sessionStorage.removeItem(key);
  }

  public clear(storageType: string = Constants.StorageType.Local): void {
    storageType === Constants.StorageType.Local
      ? localStorage.clear()
      : sessionStorage.clear();
  }
}
