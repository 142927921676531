<div class="not_available">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-auto text-center">
        <img
          src="/assets/img/icon-footer1.gif"
          alt=""
          class="mt-5"
          width="100"
        />
        <h3 class="mt-4">{{ message }}</h3>
        <p style="max-width: 400px">
          Accede a nuestro catálogo de eventos y compra tus tickets de forma
          fácil, rápida y segura.
        </p>
      </div>
    </div>
  </div>
</div>
