import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-challenge-completed',
  templateUrl: './challenge-completed.component.html',
  styleUrls: ['./challenge-completed.component.css'],
})
export class ChallengeCompletedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.close();
  }
}
