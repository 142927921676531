<section>
  <header class="d-flex align-items-center m-3" style="min-height: 100px">
    <div class="container-fluid my-2">
      <div class="row justify-content-center">
        <div class="col-auto text-uppercase text-center">
          <h1 class="display-1 fw-bold mb-1" style="letter-spacing: -1px">
            Contacto
          </h1>
          <p class="text-secoundary" style="max-width: 800px">
            "Buy Moments - Not Things"
          </p>
        </div>
      </div>
    </div>
  </header>
  <div class="position-relative py-3">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-8 col-md-6">
          <h3 class="h5 fw-bold mb-3">Datos de contacto</h3>
          <form
            [formGroup]="sendMessageForm"
            id="send-message-form"
            (submit)="handleSubmit()"
          >
            <div class="mb-3 form-floating">
              <input
                appFormInput
                formControlName="fullName"
                type="text"
                class="form-control"
                id="fullNameInput"
                placeholder="Nombre y apellidos"
              />
              <label for="fullNameInput" class="form-label"
                >Nombre completo</label
              >
            </div>
            <div class="mb-3 form-floating">
              <input
                appFormInput
                formControlName="email"
                type="email"
                class="form-control"
                id="emailInput"
                placeholder="name@example.com"
              />
              <label for="emailInput" class="form-label"
                >Correo electrónico</label
              >
              <div class="invalid-feedback" *ngIf="emailInvalid">
                Este no es un correo válido
              </div>
            </div>
            <div class="mb-3 form-floating">
              <input
                appFormInput
                formControlName="phone"
                type="tel"
                class="form-control"
                id="phoneInput"
                placeholder="+1(809)888-8888"
              />
              <label for="phoneInput" class="form-label">Teléfono</label>
            </div>
            <hr />
            <div class="h6 mb-2">Mensaje</div>
            <div class="mb-3 form-floating">
              <input
                appFormInput
                formControlName="subject"
                type="text"
                class="form-control"
                id="subjetInput"
                placeholder="Tema a tratar"
              />
              <label for="subjetInput" class="form-label">Asunto</label>
            </div>
            <div class="mb-3 form-floating">
              <textarea
                appFormInput
                formControlName="comment"
                class="form-control"
                placeholder="Leave a comment here"
                id="commentInput"
                style="height: 200px"
              ></textarea>
              <label for="commentInput">Comentario</label>
            </div>
            <button
              form="send-message-form"
              type="submit"
              class="btn btn-dark rounded-pill px-4"
              [disabled]="sendMessageForm.invalid"
            >
              Enviar mensaje
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<core-loading message="Enviando mensaje"></core-loading>
