import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import OrderDetails, {
  TicketData,
} from '../../../../core/models/order.details';
import { OrderService } from '../../services/order.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoadingComponent } from '../../../../core/components/loading/loading.component';
import { OrderTicketsComponent } from '../order-tickets/order-tickets.component';
import { TicketStatus } from '../../../../core/models/ticket.status';
import CouponType from '../../../../core/enums/couponType.enum';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css'],
})
export class OrderDetailComponent implements OnDestroy, AfterViewInit {
  @ViewChild(LoadingComponent) loading!: LoadingComponent;
  @ViewChild(OrderTicketsComponent)
  orderTicketsCompoent!: OrderTicketsComponent;

  public order: OrderDetails | null = null;
  private _orderSubscriptor: Subscription | null = null;

  invalidOrder = false;
  isLoading = false;
  loadingOrderDetail = true;

  get codeDiscounts() {
    return (
      this.order?.discounts.filter(
        (d) => d?.coupon?.type !== CouponType.CreditCard,
      ) || []
    );
  }

  get cardDiscount() {
    return this.order?.discounts.find(
      (d) => d?.coupon?.type == CouponType.CreditCard,
    );
  }

  private pageSize = 10;
  page = 1;
  totalPages = 1;

  pageTickets: TicketData[] = [];

  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
  ) {}

  get orderCancelled() {
    return this.order?.status == TicketStatus.CANCELLED;
  }

  get startIndex() {
    return (this.page - 1) * this.pageSize;
  }

  get endIndex() {
    return this.startIndex + this.pageSize;
  }

  ngAfterViewInit() {
    const slug = this.route.snapshot.params['order_slug'];

    this._orderSubscriptor = this.orderService
      .getDetail(slug)
      .subscribe((resp) => {
        this.order = resp;

        if (this.order == null) {
          this.invalidOrder = true;
        } else {
          this.totalPages = Math.ceil(
            this.order.tickets.length / this.pageSize,
          );
          this.changePage();
        }

        this.loadingOrderDetail = false;
      });
  }

  nextPage() {
    if (this.page >= this.totalPages) return;

    this.page += 1;
    this.changePage();
  }

  prevPage() {
    if (this.page <= 1) return;

    this.page -= 1;
    this.changePage();
  }

  changePage() {
    this.pageTickets = this.order
      ? this.order.tickets.slice(this.startIndex, this.endIndex)
      : [];

    document.getElementById('ticket-1')?.scrollIntoView({ behavior: 'smooth' });
  }

  ngOnDestroy() {
    this._orderSubscriptor?.unsubscribe();
  }

  handleLoad() {
    this.loading.toggle('Preparando PDF...');
  }

  downloadTickets = async () => {
    this.isLoading = true;
    this.loading.toggle();

    await this.orderTicketsCompoent.onClickDownload();

    this.isLoading = false;
    this.loading.toggle();
  };

  getComments(): string[] {
    const comments: string[] = [];
    const ticketTypes: any = {};

    const ticketsComments = this.order?.event.ticketsComments;
    if (ticketsComments) {
      comments.push(ticketsComments);
    }

    this.order?.tickets.forEach((ticket) => {
      if (!ticketTypes[ticket.ticketType.data.id]) {
        const comment = ticket.ticketType.data.attributes.comment;
        if (comment) comments.push(comment);
        ticketTypes[ticket.ticketType.data.id] = true;
      }
    });

    return comments;
  }
}
