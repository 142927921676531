<div class="container-fluid text-center" style="max-width: 990px">
  <div class="row align-items-center">
    <div class="col-12">
      <div class="card small mt-4 rounded-tix border-0 shadow-sm">
        <div
          class="card-header bg-white border-bottom rounded-tix py-3"
          style="
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          "
        >
          <h6 class="m-0">Información del evento</h6>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-4 py-3 py-md-5 placeholder-glow">
              <div class="small text-uppercase text-muted">Evento</div>
              <p *ngIf="isLoading" class="m-0 placeholder col rounded-tix">
                Evento
              </p>
              <p *ngIf="!isLoading" class="m-0">{{ order?.event?.name }}</p>
            </div>
            <div class="col-12 col-md-4 py-3 py-md-5 placeholder-glow">
              <div class="small text-uppercase text-muted">Fecha</div>
              <p *ngIf="isLoading" class="m-0 placeholder col rounded-tix">
                Fecha
              </p>
              <p [hidden]="isLoading" class="m-0" *ngFor="let date of dates">
                {{ date | dateFormat }}
              </p>
            </div>
            <div class="col-12 col-md-4 py-3 py-md-5 placeholder-glow">
              <div class="small text-uppercase text-muted">Ubicación</div>
              <p *ngIf="isLoading" class="m-0 placeholder col rounded-tix">
                Ubicación
              </p>
              <div
                [hidden]="isLoading"
                class="m-0"
                *ngFor="let place of places"
              >
                <a
                  [href]="place.link"
                  target="_blank"
                  *ngIf="place.link != null"
                  >{{ place.place }}</a
                >
                <p *ngIf="place.link == null">{{ place.place }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
