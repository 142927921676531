import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-primary.outlet',
  templateUrl: './primary.outlet.component.html',
  styleUrls: ['./primary.outlet.component.css'],
})
export class PrimaryOutletComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
