import { Component, Input, OnInit } from '@angular/core';
import Banner from 'src/app/core/models/banner';

@Component({
  selector: 'app-advertising-list',
  templateUrl: './advertising-list.component.html',
  styleUrls: ['./advertising-list.component.css'],
})
export class AdvertisingListComponent implements OnInit {
  advertisings: Banner[] = [];
  @Input() set banners(value: Banner[]) {
    this.advertisings = value;
  }

  constructor() {}

  ngOnInit(): void {}

  onClickBanner(banner: Banner): void {
    if (banner.link !== null) {
      window.open(banner.link, '_blank');
    }
  }
}
