//ENVIRONMENT FOR STAGING
export const environment = {
  production: false,
  apiUrl: 'https://stagingapi.tix.do/api',
  uploadsUrl: 'https://stagingapi.tix.do/uploads',
  baseUrl: 'https://stagingapi.tix.do',
  vendorsUrl: 'https://stagingvendors.tix.do',
  encryptionKey: 'wTl18UAxQn',
  orderCheckoutTimeoutInSeconds: 240, // 4 minutes
  apiCardinal: 'https://centinelapi.cardinalcommerce.com',
};
