import { Component, OnDestroy } from '@angular/core';
import { StorageService } from '../../../../core/services/storage.service';
import { ActivatedRoute } from '@angular/router';
import Event from '../../../../core/models/event';
import { EventService } from '../../services/event.service';
import Banner from '../../../../core/models/banner';
import TicketType from '../../../../core/models/ticket.type';
import { Subscription } from 'rxjs';
import * as events from 'events';
import { ResponseTicketTypeOrGroupedByDate } from '../../services/ticket.service';

@Component({
  selector: 'app-base-event',
  templateUrl: './base-event.component.html',
  styleUrls: ['./base-event.component.css'],
})
export abstract class BaseEventComponent implements OnDestroy {
  protected loading = true;
  protected event: Event | null = null;
  protected banners: Banner[] = [];
  protected ticketTypeList: ResponseTicketTypeOrGroupedByDate = [];

  protected routeSubscription: Subscription = this.route.params.subscribe(
    (params) => {
      this._init(params['event_slug']);
    },
  );

  protected constructor(
    protected eventService: EventService,
    protected storageService: StorageService,
    protected route: ActivatedRoute,
  ) {}

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  private async _init(slug: string): Promise<void> {
    const _event = await this.eventService.getEvents(slug).toPromise();

    this.loading = false;

    if (_event && _event[0].slug == slug) {
      this.event = _event[0];
      this.onInit();
    }
  }

  abstract onInit(): void;
}
