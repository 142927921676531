<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-12 col-md-8">
      <h2 class="my-4 display-6 text-center">Políticas de servicio</h2>
      <ol class="list-group list-group-numbered">
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            El comprador y/o portador de esta boleta electrónica reconoce y acepta que asiste al evento de manera voluntaria y consciente, por lo que asume todos los riesgos que puedan suceder, desde lesiones personales hasta la muerte o cualquier percance que pueda ocurrir previo, durante o posterior al evento.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Reconoce que TIX.do es el prestador del servicio de boletería y no el organizador y/o propietario responsable del evento, y por lo tanto desiste y renuncia irrevocablemente, desde ahora y para siempre y sin reservas de ningún tipo a demandas, reclamaciones o instancias que pudiere tener en contra de TIX, sus accionistas, directores, representantes o empleados, empresas afiliadas, que tengan su fundamento o que estén relacionadas directa o indirectamente con el evento de cualquier tipo de naturaleza.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            En caso de eventos deportivos, igualmente, el portador de esta boleta asume todo riesgo asociado con la actividad o deporte de que se realice previo, durante o posterior a las practicas o juegos, incluyendo específicamente: el ser lesionado por objetos lanzados y/o fragmentos de los mismo y está de acuerdo en que el organizador del evento, sus agencias y empleados, patrocinadores, equipos, jugadores, etc... No son responsable de lesiones ocasionadas por dichas causas.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            En caso de un inconveniente de fuerza mayor que impida la realización del evento, la empresa organizadora se reserva el derecho de alterar y modificar la fecha y el lugar, lo cual será comunicado por medio de la prensa nacional o redes sociales.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            TIX no asume responsabilidad alguna sobre la organización del evento, su postergación o cancelación, la cual es asumida por el titular de esta boleta, quien asume todo riesgo y peligro derivado del mismo.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            En caso de lluvia, fallas técnicas, enfermedad o cualquier otra causa el evento podría ser pospuesto y si sucediera dicha postergación la boleta mantendrá su validez hasta la fijación de una nueva fecha, la cual será aceptada por el titular que renuncia a toda reclamación por daños y perjuicios por dichos cambios, reconociendo que la postergación no dará derecho a reembolso de ningún tipo.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            En el caso de que el organizador o responsable del evento anule o cancele el evento, será responsabilidad exclusiva del mismo la devolución total o parcial, según proceda a cada uno de los participantes.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Para Cualquier reclamo, incluyendo las solicitudes de reembolso, comprobante fiscal y devoluciones de dinero por compra de boletas, el titular se dirigirá directamente al organizador o responsable del evento.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Las boletas adquiridas serán válidas para la fecha definitiva y de decidirse su reembolso por suspensión, se realizará en un tiempo prudente fijado por la empresa organizadora, sin ningún tipo de reajuste o indemnización, tomando como precio el fijado en el valor de la misma.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            En caso de cancelación o reembolso, por cualquier motivo de parte de la empresa organizadora, EL CARGO POR SERVICIO REFLEJADO (Cargo por servicio) NO SERA REEMBOLSADO.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Previo al acceso o entrada al recinto, toda persona está sujeta a una revisión personal para fines de seguridad general, de acuerdo a las leyes vigentes. La negativa a esta condición o requisito implica la imposibilidad de acceso al recinto y la renuncia a este derecho.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            La empresa organizadora no garantiza la AUTENTICIDAD DE LAS BOLETAS de no haber sido adquiridas a través de TIX.do y pueden ser confiscadas por la empresa organizadora en caso de que así fuese.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            El titular reconoce y acepta que la presente boleta contiene una numeración o código único o individual que dará derecho a un solo acceso por boleta, por lo que el titular será el único responsable de salvaguardar la confidencialidad del código contenido en su boleta.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            El titular acepta el riesgo de falsificación, copia como manipulaciones o duplicidad en cuyo caso solo la primera persona en presentar la boleta o código tendrá acceso al evento.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            No se permite la entrada de cámaras fotográficas y de videos, armas de fuego, botellas, paraguas, comidas, ni bebidas de ningún tipo; objetos contundentes y/o punzantes o que representen peligro, drogas, fármacos, metales, maderas, rollos de papel, fósforos, encendedores, material pirotécnico, punteros laser, envases de vidrio o plástico al evento.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Esta boleta no podrá ser utilizada en ningún caso para su reventa.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Esta boleta será anulada de declararse robada o perdida.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Si la boleta posee algún tipo de alteración digital, el portador perderá el derecho al acceso del evento o recinto.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            El portador no podrá transmitir o ayudar a transmitir ninguna descripción, relato, fotografía o reproducción del evento al que esta boleta permita acceso.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            El portador autoriza a utilizar su imagen o semejanza en cualquier exposición en vivo, como grabación de video o cualquier otro material audiovisual al que esta boleta permita acceso.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            El organizador se reserva el derecho de revocar esta boleta y los derechos que confiere, mediante el reembolso del precio de compra del mismo.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Esta boleta no podrá ser empleada para fines de publicidad, anuncios, promociones (incluyendo concursos y rifas), o cualquier otro propósito comercial sin el consentimiento expreso por escrito del organizador.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            La compra o adquisición de esta boleta por vías fraudulentas o ilegales, ya sea por tarjetas de crédito o débito clonadas o sin consentimiento del tarjeta habiente, llevará a cabo una fuerte aplicación de la ley, lo cual podrá reflejar persecución, retención, cárcel, demandas, etc... Afectando al portador y/o persona culpable de esta acción.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            En caso de que se determine que la boleta ha sido adquirida a través de TIX.do de
            manera fraudulenta, TIX.do se reserva el derecho de cancelar dicha boleta, como
            también la empresa organizadora se reserva el derecho de admisión o acceso al
            evento o recinto.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Cualquier violación a lo anterior revocará automáticamente la validez de la boleta.
          </div>
        </li>
      </ol>
      <h2 class="my-4 display-6 text-center">Políticas de seguridad</h2>
      <ol class="list-group list-group-numbered">
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Tomamos todas las medidas y precauciones razonables para proteger tu información personal y seguimos las mejores prácticas de la industria para asegurar que tu información no sea utilizada de manera inapropiada, alterada o destruida.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Ciframos la información de tu tarjeta de crédito utilizando la tecnología de capa de puertos seguros o SSL (Secure Sockets Layer), como también seguimos todos los requerimientos del PCI-DSS.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Los métodos de pago utilizado por LA EMPRESA son servicios de terceros. Estos servicios de terceros (AZUL), cumplen con todos los estándares de seguridad y cifrado para mantener tu información segura. Solo utilizarán la información necesaria para completar el proceso requerido. También es recomendable leer las políticas de Privacidad de estos proveedores, para entender mejor como manejan la información suministrada.
          </div>
        </li>
      </ol>
      <h2 class="my-4 display-6 text-center">Políticas de privacidad</h2>
      <ol class="list-group list-group-numbered mt-4">
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Cuando interactúas con nosotros a través de nuestra página web, podemos recopilar datos personales. A veces esto será en nuestro propio nombre y otras veces será en nombre de un Organizador que utiliza nuestros Servicios para llevar a cabo un evento. Los datos personales que podemos recopilar incluyen, entre otros, tu nombre, dirección, dirección de correo electrónico y cualquier otra información que decidas proporcionar y/o que permita la identificación personal de los Usuarios.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Si proporcionas datos personales con el fin de obtener acceso a los servicios, o cualquier otra actividad relacionada, utilizaremos dichos datos personales para proporcionarte acceso a los servicios.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Podemos utilizar tus datos personales para nuestros propósitos de marketing y publicidad, incluidos por correo electrónico, marketing por SMS, y otros. Hacemos esto con el fin de informarte sobre servicios o eventos que creemos que pueden ser de tu interés, desarrollar materiales promocionales o de marketing y mostrar contenido de TIX.do o relacionado con eventos y publicidad dentro o fuera de los servicios.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Si deseas eliminar tus datos de nuestra plataforma, puedes hacerlo solicitándolo a través de info@tix.do y procederemos a remover tu información de inmediato.
          </div>
        </li>
      </ol>
      <h2 class="my-4 display-6 text-center">Políticas de entrega</h2>
      <ol class="list-group list-group-numbered mt-4">
        <li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
          <div class="ms-2 me-auto">
            Al comprar tu ticket a través de nuestra página web recibirás
            un correo electrónico confirmando tu orden. En dicho correo podrás ver el detalle de
            tu compra y un botón &quot;Ver mis tickets&quot; donde podrás ver tus entradas adquiridas.                        </div>
        </li><li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
        <div class="ms-2 me-auto">
          En caso de no recibir el correo de confirmación de orden, por favor contáctanos a
          info@tix.do.
        </div>
      </li><li class="list-group-item d-flex justify-content-between align-items-start px-4 py-3">
        <div class="ms-2 me-auto">
          Todos nuestro tickets son digitales.
        </div>
      </li>
      </ol>
    </div>
  </div>
</div>
