import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(
    date: Date | string | undefined | null,
    format: string = 'MMMM D, y h:mm a',
  ): string | null {
    return moment(date).tz('America/Santo_Domingo').locale('es').format(format);
  }
}
