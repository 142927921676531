import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import Banner from '../../../core/models/banner';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CarouselService {
  constructor(private http: HttpClient) {}

  getBanners(): Observable<Banner[]> {
    const filters = this.filters();
    return this.http
      .get(`${environment.apiUrl}/banners?${filters}&sort=priority:asc`)
      .pipe(
        map((resp: any) => {
          const respMapped: Banner[] = [];
          resp.data.forEach((data: any) => {
            respMapped.push({
              id: data.id,
              active: data.attributes.active,
              description: data.attributes.description,
              endAt: data.attributes.end_at,
              imageUri: data.attributes.image.data.attributes.url,
              mobileUri: data.attributes.image_mobile.data?.attributes.url,
              link: data.attributes.link,
              priority: data.attributes.priority,
              startAt: data.attributes.start_at,
              title: data.attributes.title,
              show_as_advertising: data.attributes.show_as_advertising,
            });
          });
          return respMapped;
        }),
        catchError(() => of([])),
      );
  }

  private filters() {
    const active = true;
    const todayStart = moment().format('YYYY-MM-DD').split('T')[0];

    return `filters[start_at][$lte]=${todayStart}&filters[end_at][$gte]=${todayStart}&filters[active]=${active}`;
  }

  private appendZero(n: number) {
    return n > 9 ? n : `0` + n;
  }
}
