import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { DetailService } from '../../../pages/detail/services/detail.service';

@Component({
  selector: 'unavailable-button',
  templateUrl: './unavailable-button.component.html',
  styleUrls: ['./unavailable-button.component.css'],
})
export class UnavailableButtonComponent implements OnChanges, OnDestroy {
  @Input() soldOut = false;
  @Input() holdedOut = false;

  //5 minutes
  time = 300000;

  interval: any;

  constructor(private detailService: DetailService) {}

  get timer() {
    return this.millisToMinutesAndSeconds(this.time);
  }

  ngOnChanges() {
    if (this.holdedOut && !this.interval) {
      this.interval = setInterval(() => {
        if (this.time) {
          this.time -= 1000;
        } else {
          clearInterval(this.interval);
          this.detailService.refreshTicketTypes();
        }
      }, 1000);
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  millisToMinutesAndSeconds(millis: number) {
    const minutes = Math.floor(millis / 60000);
    const seconds = parseInt(((millis % 60000) / 1000).toFixed(0));
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }
}
