<div
  class="add-ticket-type d-flex justify-content-center flex-column align-items-center"
>
  <button
    class="btn btn-outline-secondary btn-sm px-3 fw-semibold rounded rounded-pill position-relative bg-white border-danger text-danger px-4"
  >
    {{ soldOut ? "Agotado" : "Espere..." }}
  </button>
  <span *ngIf="holdedOut" class="text-danger">{{ timer }}</span>
</div>
