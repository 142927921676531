<section [id]="sectionId ?? ''">
  <div
    id="carouselCompontent"
    class="carousel slide"
    (mouseenter)="handleMouseEnter()"
    (mouseleave)="handleMouseLeave()"
  >
    <div class="carousel-indicators" *ngIf="banners.length > 1">
      <button
        [class.active]="activeBanner == i"
        *ngFor="let banner of banners; index as i"
        type="button"
        data-bs-target="#carouselCompontent"
        [attr.data-bs-slide-to]="i"
        [attr.aria-current]="activeBanner == i"
        [attr.aria-label]="'Slide' + (i + 1)"
      ></button>
    </div>
    <div
      class="carousel-inner"
      style="aspect-ratio: 1/1"
      *ngIf="square; else nonSquare"
    >
      <div
        [class.active]="activeBanner == i"
        class="carousel-item"
        *ngFor="let banner of banners; index as i"
        (click)="onClickBanner(banner)"
      >
        <img
          [src]="getBannerAbsoluteUrl(banner.imageUri)"
          style="object-fit: contain; height: auto"
          class="d-block w-100"
          alt="..."
        />
      </div>
    </div>
    <ng-template #nonSquare>
      <div class="carousel-inner" *ngIf="!mobile; else mobileCarousel">
        <div
          [class.active]="activeBanner == i"
          [class.pointer]="banner.link"
          class="carousel-item"
          *ngFor="let banner of banners; index as i"
          (click)="onClickBanner(banner)"
        >
          <img
            [src]="getBannerAbsoluteUrl(banner.imageUri!)"
            class="d-block w-100"
            alt="..."
          />
        </div>
      </div>
      <ng-template #mobileCarousel>
        <div class="carousel-inner" style="aspect-ratio: 1/1">
          <div
            [class.active]="activeBanner == i"
            class="carousel-item"
            *ngFor="let banner of banners; index as i"
            (click)="onClickBanner(banner)"
          >
            <img
              [src]="getBannerAbsoluteUrl(banner.mobileUri ?? banner.imageUri)"
              style="object-fit: contain; height: auto"
              class="d-block w-100"
              alt="..."
            />
          </div>
        </div>
      </ng-template>
    </ng-template>
    <ng-container *ngIf="banners.length > 1">
      <button
        #prev
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselCompontent"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        #next
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselCompontent"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </ng-container>
  </div>
</section>
