import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-three-dschallenge',
  templateUrl: './three-dschallenge.component.html',
  styleUrls: ['./three-dschallenge.component.css'],
})
export class ThreeDSChallengeComponent {
  @ViewChild('threeDsChallengeBtn')
  threeDsChallengeBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('challengeForm') challengeForm!: ElementRef<HTMLFormElement>;

  formConfig = {
    RedirectPostUrl: '',
    CReq: '',
    TermUrl: '',
  };
}
