<div class="add-ticket-type d-flex gap-2 align-items-center">
  <button
    *ngIf="usedTickets > 0"
    class="btn btn-sm btn-light d-flex text-muted p-1 rounded-circle"
    (click)="removeSelection()"
  >
    <span class="material-symbols-outlined" style="font-size: 20px">
      close
    </span>
  </button>
  <div
    class="d-flex justify-content-between align-items-center bg-white border border-dark position-relative rounded rounded-pill"
  >
    <button
      type="button"
      class="btn-outline-light text-dark pt-1 px-2 pb-0 btn border-0 rounded-circle rounded text-light"
      (click)="toggleTicket(statusUsed, statusAvailable)"
      [disabled]="usedTickets === 0"
    >
      <span class="material-symbols-outlined small-icon">remove</span>
    </button>
    <div class="quantity-label h-100 fw-semibold small text-center">
      {{ selected }}
    </div>
    <button
      type="button"
      class="btn-outline-light text-dark pt-1 px-2 pb-0 btn border-0 rounded-circle rounded text-light increase-button"
      (click)="toggleTicket(statusAvailable, statusUsed)"
      [disabled]="isTicketLimitReached()"
    >
      <span class="material-symbols-outlined small-icon">add</span>
    </button>
  </div>
</div>
