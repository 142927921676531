import { Component, OnInit } from '@angular/core';
import { TixConfigurationService } from '../../../../core/services/tix-configuration.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})
export class FaqComponent implements OnInit {
  public vendorRegisterUrl: string = '';
  constructor(private tixConfigurationService: TixConfigurationService) {}

  ngOnInit(): void {
    this.tixConfigurationService
      .getVendorRegisterUrl()
      .subscribe((url) => (this.vendorRegisterUrl = url));
  }
}
