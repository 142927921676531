import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-return-policies-modal',
  templateUrl: './return-policies-modal.component.html',
  styleUrls: ['./return-policies-modal.component.css'],
})
export class ReturnPoliciesModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
