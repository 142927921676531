import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { StorageService } from '../../../../core/services/storage.service';
import { Location } from '@angular/common';
import Order from '../../../../core/models/order';
import { Constants } from '../../../../core/utils/constants';
import TicketType from '../../../../core/models/ticket.type';
import { UtilService } from '../../../../core/services/util.service';
import { EventService } from '../../services/event.service';
import { CouponService } from '../../services/coupon.service';
import PaymentInfo from 'src/app/core/models/payment.info';
import { LoadingComponent } from '../../../../core/components/loading/loading.component';
import { environment } from '../../../../../environments/environment';
import { FormControl, Validators } from '@angular/forms';
import { lastValueFrom, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ThreeDSChallengeComponent } from './three-dschallenge/three-dschallenge.component';
import { OrderService } from '../../../order/services/order.service';
import { LocationService } from 'src/app/core/services/location.service';
import Coupon from '../../../../core/models/coupon';
import CouponType from '../../../../core/enums/couponType.enum';
import { getOrderCreationErrorMessage } from 'src/app/core/utils/getOrderCreationErrorMessage';
import { OrderCreationError } from 'src/app/core/enums/orderCreationError.enum';
import { ThreeDSCybersourceService } from 'src/app/services/three-dscybersource.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(LoadingComponent) loadingComponent!: LoadingComponent;
  @ViewChild('threeChallengeModal')
  threeChallengeModal!: ThreeDSChallengeComponent;

  showSummary = false;
  emailInput = new FormControl('', [Validators.required, Validators.email]);
  emailInputSubscription: Subscription | undefined;
  dates: string[] = [];
  gateway: string = '';

  couponError = '';

  public order: Order | null = null;
  public couponCode: string = '';
  public couponButtonLabel: string = 'Aplicar';
  public error:
    | {
        code: string;
        error: string;
        msg: string;
        iso_code: string;
        tix_error: OrderCreationError;
      }
    | string
    | { recommendation: string; response: string; code: string }
    | { code: string; reason: string; message: string }
    | undefined = undefined;
  public insuranceFee: number = 0;

  public checkoutTimestamp: number = 0;

  public timeoutMinutes: number = -1;
  public timeoutSeconds: number = -1;

  private countDownInterval: NodeJS.Timer | null = null;

  public buttonStatus = {
    coupon: true,
    checkout: true,
  };

  public buyerData = {
    name: '',
    email: '',
    coupon: null,
  };

  public paymentInfo: PaymentInfo = {
    card: '',
    cvc: '',
    expDate: '',
    name: '',
  };

  get eventFree() {
    return this.order?.subTotal === 0;
  }

  get codeCoupons() {
    return this.order?.coupons?.filter((c) => c.type == CouponType.Code) || [];
  }

  getValidTicketTypes(coupon: Coupon) {
    if (!coupon.ticket_types?.length) return '';

    const names = coupon.ticket_types.map((tt) => tt.name).join(', ');

    return `PARA TICKETS: ${names}`;
  }

  get isEmailInvalid() {
    const result = this.emailInput.errors
      ? this.emailInput.errors['email']
      : false;

    return result;
  }

  get ticketsReleasedEvents() {
    return 'on_hold_tickets_released';
  }

  get startTimerEvent() {
    return 'start_checkout_timer';
  }

  get codeCouponsDiscount() {
    return this.orderService.codeCouponsDiscount(this.order);
  }

  get couponsNames() {
    return this.codeCoupons.map((c) => c.code).join(', ');
  }

  threeDSForm: SafeHtml = '';
  cardCoupon: Coupon | undefined;

  constructor(
    private route: ActivatedRoute,
    private storageService: StorageService,
    private router: Router,
    private location: Location,
    private utilService: UtilService,
    private eventService: EventService,
    private couponService: CouponService,
    private orderService: OrderService,
    private sanitizer: DomSanitizer,
    private locationService: LocationService,
    private threedsCybersourceService: ThreeDSCybersourceService,
  ) {}

  ngOnInit(): void {
    this.emailInputSubscription = this.emailInput.valueChanges.subscribe(
      (value) => {
        this.buyerData.email = value!;
      },
    );

    try {
      this.order =
        JSON.parse(this.storageService.get(Constants.Keys.EventOrder) ?? '') ??
        null;

      if (this.order != null) {
        const _timestamp = this.storageService.get(
          Constants.Keys.CheckoutTimeout,
          Constants.StorageType.Session,
        );

        if (_timestamp !== null) {
          this.checkoutTimestamp = parseInt(_timestamp);
        } else {
          this.checkoutTimestamp = 0;
        }

        this._setTicketSocketListener();

        this.order.insuranceFee = this.insuranceFee;

        const ticketsPrice: number[] = [];

        this.order.tickets.forEach((ticketType) => {
          ticketType.tickets.forEach((ticket) => {
            ticketsPrice.push(ticket.price ?? ticket.ticket_type.price);
          });
        });

        this.addStartOnDates(this.order.tickets);

        this.eventService
          .getOrderRequiredFee({ ticketsPrice, total: this.order.subTotal })
          .subscribe((resp) => {
            this.gateway = resp?.gateway?.toUpperCase() || 'AZUL';
            if (this.order && resp != null) {
              this.order.serviceFee = resp.serviceFee;

              this.insuranceFee = resp.insuranceFee;
              this.applyInsuranceFee();
            }
            this.calcTotal();
          });
      }
    } catch (err: any) {
      this.router.navigate([`event/${this.route.snapshot.params['slug']}`]);
    }
  }

  ngAfterViewInit() {
    window.scroll(0, 0);
  }

  ngOnDestroy() {
    this.emailInputSubscription?.unsubscribe();
    this._clearSession();
  }

  private _clearSession(pending = true): void {
    if (this.countDownInterval != null) {
      if (pending)
        this.utilService
          .getSocket()
          .emit('release_hold_tickets', this.order?.ticketIds ?? []);
      this.utilService.getSocket().removeListener(this.ticketsReleasedEvents);
      this.utilService.getSocket().removeListener(this.startTimerEvent);

      clearInterval(this.countDownInterval as any);
      this.countDownInterval = null;
    }

    this.order = null;
    this.storageService.remove(Constants.Keys.EventOrder);
    this.storageService.remove(
      Constants.Keys.CheckoutTimeout,
      Constants.StorageType.Session,
    );
    this.storageService.remove(
      Constants.Keys.CheckoutTimeoutRemainingSeconds,
      Constants.StorageType.Session,
    );
    this.storageService.remove(
      Constants.Keys.CheckoutTimeoutTicketHeld,
      Constants.StorageType.Session,
    );
  }

  back(): void {
    this._clearSession();
    this.location.back();
  }

  applyInsuranceFee(): void {
    if (this.order) this.order.insuranceFee = this.insuranceFee;

    this.calcTotal();
  }

  removeInsuranceFee(): void {
    if (this.order) this.order.insuranceFee = 0;

    this.calcTotal();
  }

  calcTotal(): void {
    if (this.order) {
      const { subTotal, insuranceFee, serviceFee } = this.order;

      let cardDiscount = this.cardCoupon
        ? this.order.subTotal * (this.cardCoupon.discount / 100)
        : 0;

      if (this.cardCoupon) {
        const result = this.order.coupons?.find(
          (c) => c.id == this.cardCoupon?.id,
        );
        result!.discounted = cardDiscount;
      }

      const discountedSubTotal =
        subTotal - this.codeCouponsDiscount - cardDiscount;

      this.order.discounted = this.codeCouponsDiscount - cardDiscount;
      this.order.totalToPay = discountedSubTotal + insuranceFee + serviceFee;
    }
  }

  getEventImageUrl(): string {
    return this.utilService.getAbsoluteUrl(this.order?.event?.coverUri ?? '');
  }

  getTotalTickets(): number {
    return (
      this.order?.tickets?.reduce(
        (count: number, tt: TicketType) => count + tt.tickets.length,
        0,
      ) ?? 0
    );
  }

  applyCoupon(): void {
    if (!(this.order && this.order.event)) {
      Swal.fire({
        title: `Error`,
        html: 'no order',
        icon: 'error',
      });
      return;
    }

    if (this.couponCode) {
      this.buttonStatus.coupon = false;
      this.couponButtonLabel = '...';
      this.couponService
        .find(
          this.couponCode,
          this.order.event.id,
          this.order.tickets.map((t) => t.id),
        )
        .subscribe((_coupon) => {
          if (typeof _coupon == 'string') {
            this.couponError =
              Constants.CouponErrors[_coupon] || 'Cupón inválido';
            _coupon = null;
          } else {
            this.couponError = '';
          }

          this.buttonStatus.coupon = true;
          this.couponButtonLabel = 'Aplicar';

          if (this.order && !this.order.coupons) this.order.coupons = [];

          if (
            this.order &&
            !this.order.coupons?.find((c) => c.id == _coupon?.id) &&
            _coupon
          ) {
            this.order.coupons?.push(_coupon);
          }

          this.calcTotal();
        });
    }
  }

  getErrorMessage(tixError?: OrderCreationError, isoCode?: string) {
    if (tixError) {
      return getOrderCreationErrorMessage(tixError);
    }

    let response;
    let recommendation;

    if (!isoCode) {
      isoCode = '-1';
    }

    if (Constants.TransactionErrors[isoCode]) {
      response = Constants.TransactionErrors[isoCode].response;
      recommendation = Constants.TransactionErrors[isoCode].recommendation;
    } else {
      response = Constants.TransactionErrors['-1'].response;
      recommendation = Constants.TransactionErrors['-1'].recommendation;
    }

    return {
      code: isoCode,
      response: `(${isoCode}) ${Constants.TransactionErrors[isoCode].response}`,
      recommendation:
        Constants.TransactionErrors[isoCode].recommendation.toUpperCase(),
    };
  }

  paymentError(error: typeof this.error) {
    this._startTimer(this.order!.ticketIds);

    this.buttonStatus.checkout = true;
    this.error = error;

    let tixError: OrderCreationError | undefined;
    let isoCode: string | undefined;

    if (typeof error === 'object' && 'tix_error' in error) {
      tixError = error.tix_error;
    }

    this._hideLoadingDialog(() => {
      if (!error) {
        error = '';
      }

      if (
        typeof error === 'object' &&
        'iso_code' in error &&
        !Constants.TransactionErrors[error!.iso_code]
      ) {
        error = error?.iso_code;
      }

      if (typeof error === 'object' && 'iso_code' in error) {
        isoCode = error?.iso_code;
      } else if (typeof error === 'object' && 'code' in error) {
        isoCode = error.code;
      }

      let msg: any;
      if (typeof error === 'object' && 'recommendation' in error) {
        msg = error;
      } else {
        msg = this.getErrorMessage(tixError, isoCode);
      }

      //TODO: Define a better way to notify client about any error regarding order payment
      Swal.fire({
        title: `Declinada`,
        html:
          typeof error == 'string'
            ? error
            : `<div>
                <p class="mb-4">${msg?.code}</p>
                <p class="mb-4">${msg.response}</p>
                <p>
                 ${!tixError ? msg.recommendation : ''}
                </p>
              </div>`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    });
  }

  /**
   * Proceed payment order.
   * @return void
   * */
  proceedPayment(): void {
    if (this.order) {
      if (this.isFormValid()) {
        this.stopTimer();

        this.buttonStatus.checkout = false;
        this.order.buyerEmail = this.buyerData.email;
        this.order.buyerName = this.buyerData.name;

        if (!this.loadingComponent?.isVisible()) {
          this.loadingComponent?.toggle(); // Show dialog
        }

        this.eventService.checkout(this.order, this.paymentInfo).subscribe({
          next: (resp) =>
            this._onPaymentProceed(resp, this.paymentInfo, this.order),
          error: (err) => this.paymentError(err),
        });
      } else {
        alert('Complete datos personales y de pago.');
      }
    }
  }

  /**
   * Validate checkout form
   * @return boolean
   * */
  isFormValid(): boolean {
    const paymentValidation = this.eventFree
      ? true
      : this.paymentInfo.name != '' &&
        this.paymentInfo.card != '' &&
        this.paymentInfo.cvc != '' &&
        this.paymentInfo.expDate != null;

    return (
      this.buyerData.name != '' &&
      this.buyerData.email != '' &&
      paymentValidation &&
      this.emailInput.valid
    );
  }

  private async handleCybersourceThreeDSSession(
    resp: any,
    paymentInfo?: any,
    order?: any,
  ) {
    const { accessToken, deviceDataCollectionUrl, referenceId } =
      resp.consumerAuthenticationInformation;
    const onGettingResult = () => {
      this._hideLoadingDialog(() => {});
    };

    const data = await this.threedsCybersourceService.handleThreeDSSession({
      ddp: { accessToken, deviceDataCollectionUrl, referenceId },
      paymentInfo,
      order,
      paymentHistoryId: resp.payment_history_id,
      onGettingResult,
    });

    return data;
  }

  private async _onPaymentProceed(
    resp: any,
    paymentInfo?: any,
    order?: any,
  ): Promise<void> {
    if ('consumerAuthenticationInformation' in resp) {
      const data = await this.handleCybersourceThreeDSSession(
        resp,
        paymentInfo,
        order,
      );

      if (!data) {
        this.paymentError('La orden no pudo ser completada');
        return;
      }

      if ('code' in data) {
        this.paymentError(data);
        return;
      }

      if (!this.order) {
        this.paymentError('La orden no pudo ser completada');
        return;
      }

      this.order.id = data.orderId;
      this.order.slug = data.slug;
      this.order.createdAt = data.createdAt;

      return this.handleEndSuccessPaymentSession(data);
    }

    if (resp.ResponseMessage === '3D_SECURE_2_METHOD') {
      this.threeDSForm = this.sanitizer.bypassSecurityTrustHtml(
        resp.ThreeDSMethod.MethodForm,
      );
      setTimeout(() => {
        const form = document.querySelector(
          '#tdsMmethodForm',
        ) as HTMLFormElement;
        form.submit();
      }, 300);
      const sid = resp.TermUrl.split('sid=')[1];
      const notificationListenerKey = `3ds_notification_${sid}`;
      this.utilService.getSocket().on(notificationListenerKey, (response) => {
        this.eventService
          .checkoutThreeds({
            data: {
              ...resp,
              notificationStatus: response.threeDSMethodData
                ? 'RECEIVED'
                : 'EXPECTED_BUT_NOT_RECEIVED',
            },
          })
          .subscribe({
            next: (resp) => this._onPaymentProceed(resp),
            error: (err) => this.paymentError(err),
          });
        this.utilService.getSocket().removeListener(notificationListenerKey);
      });

      return;
    } else if (resp.ResponseMessage === '3D_SECURE_CHALLENGE') {
      const challengeData = resp.ThreeDSChallenge;

      this.threeChallengeModal.formConfig = {
        CReq: challengeData.CReq,
        TermUrl: resp.TermUrl,
        RedirectPostUrl: challengeData.RedirectPostUrl,
      };

      setTimeout(() => {
        this.loadingComponent.toggle();
        this.threeChallengeModal.threeDsChallengeBtn.nativeElement.click();
        this.threeChallengeModal.challengeForm.nativeElement.submit();
      }, 300);

      const sid = resp.TermUrl.split('sid=')[1];
      const notificationListenerKey = `3ds_notification_${sid}`;
      this.utilService.getSocket().on(notificationListenerKey, (response) => {
        this.loadingComponent.toggle();
        this.threeChallengeModal.threeDsChallengeBtn.nativeElement.click();
        this.eventService
          .checkoutThreeds({
            data: {
              ...resp,
              CRes: response.cres,
            },
          })
          .subscribe({
            next: (resp) => this._onPaymentProceed(resp),
            error: (err) => this.paymentError(err),
          });
        this.utilService.getSocket().removeListener(notificationListenerKey);
      });
      return;
    }

    if (!resp.id) return this.paymentError(resp);

    if (this.order && resp) {
      this.order.id = resp.id;
      this.order.createdAt = resp.createdAt;
      this.order.slug = resp.slug;

      return this.handleEndSuccessPaymentSession(resp);
    } else {
      //TODO: Define an error catch flow
      this.paymentError('La orden no pudo ser completada');
    }
  }

  private handleEndSuccessPaymentSession(data: { slug: string }) {
    this._hideLoadingDialog(() => {
      if (this.countDownInterval != null) {
        clearInterval(this.countDownInterval as any);
        this.countDownInterval = null;
      }
      this.storageService.set(
        Constants.Keys.OrderConfirmation,
        JSON.stringify(this.order),
      );
      this._clearSession(false);

      this.router.navigate([`/order/${data?.slug}/confirmation`]);
    });
  }

  /**
   * Hides the loading dialog.
   * This expression was put inside a particular method because a bug when the dialog is request to be closed.
   * It seems that if both events (show and hide) occur within a small difference of time, dismiss doesn't work
   * properly.
   *
   * The call to toggle function is wrapped inside a setTimeout call that takes 1 second to execute.
   * @param callback
   * @return void
   * */
  private _hideLoadingDialog(callback: Function): void {
    //TODO: Behavior bug should be fixed.
    if (this.loadingComponent?.isVisible()) {
      setTimeout(() => {
        this.loadingComponent?.toggle();
        setTimeout(() => {
          callback();
        }, 500);
      }, 500);
    } else {
      callback();
    }
  }

  private _setTicketSocketListener(): void {
    //TODO: Below socket commented function shall be deleted

    /*
      //Listen to release ticket event from backend
      this.utilService.getSocket().on(this.ticketsReleasedEvents, (data: any) => {
        if(this.order != null) {
          this.storageService.remove(Constants.Keys.EventOrder);

          this.storageService.remove(Constants.Keys.CheckoutTimeout, Constants.StorageType.Session);
          // Redirect user to event details.
          alert("Tiempo de espera para procesar orden excedido.")
          this.back();
        }
      });
    */

    if (this.checkoutTimestamp == 0) {
      // Start timer (countdown)

      this.checkoutTimestamp = Date.now();

      const ids = this.order?.ticketIds ?? [];

      this.storageService.set(
        Constants.Keys.CheckoutTimeout,
        this.checkoutTimestamp.toString(),
        Constants.StorageType.Session,
      );
      this.storageService.set(
        Constants.Keys.CheckoutTimeoutTicketHeld,
        ids.toString(),
        Constants.StorageType.Session,
      );
      this._startTimer(ids);
    } else {
      const ids = this.storageService.get(
        Constants.Keys.CheckoutTimeoutTicketHeld,
        Constants.StorageType.Session,
      );
      if (ids != null) {
        this._startTimer(ids.split(','));
      }
    }
  }

  private stopTimer() {
    if (this.countDownInterval) clearInterval(this.countDownInterval as any);
  }

  private _startTimer(ids: number[] | string[]): void {
    const _secondsStored = this.storageService.get(
      Constants.Keys.CheckoutTimeoutRemainingSeconds,
      Constants.StorageType.Session,
    );
    // Start countdown timer
    let timerSeconds =
      _secondsStored != null
        ? parseInt(_secondsStored)
        : environment.orderCheckoutTimeoutInSeconds;

    this.timeoutMinutes = parseInt((timerSeconds / 60).toString());
    this.timeoutSeconds = timerSeconds - this.timeoutMinutes * 60;

    this.countDownInterval = setInterval(() => {
      timerSeconds--;
      this.storageService.set(
        Constants.Keys.CheckoutTimeoutRemainingSeconds,
        timerSeconds.toString(),
        Constants.StorageType.Session,
      );

      if (this.timeoutSeconds == 0) {
        if (this.timeoutMinutes > 0) {
          this.timeoutMinutes--;
          this.timeoutSeconds = 59;
        } else {
          this.timeoutSeconds = 0;
        }
      } else {
        if (this.timeoutSeconds > 0) this.timeoutSeconds--;
      }

      if (timerSeconds == 0) {
        // Request release tickets on hold by its id.
        this.utilService.getSocket().emit('release_hold_tickets', ids);

        // No need to wait 'til socket emit [on_hold_tickets_released] from backend, lets send the user back to details
        this._onTimerLimitReached();
      }
    }, 1000);
  }

  private _onTimerLimitReached(): void {
    if (this.order != null) {
      this.storageService.remove(Constants.Keys.EventOrder);

      this.storageService.remove(
        Constants.Keys.CheckoutTimeout,
        Constants.StorageType.Session,
      );
      // Redirect user to event details.
      alert('Tiempo de espera para procesar orden excedido.');
      this.back();
    }
  }

  handleCouponCodeChange($event: Event) {
    const target = $event.target as HTMLInputElement;

    this.couponCode = target.value.toUpperCase();
  }

  toggleSummary() {
    this.showSummary = !this.showSummary;
  }

  removeCardDiscount() {
    if (!(this.order && this.order.coupons)) return;

    const i = this.order.coupons.findIndex((c) => c.id == this.cardCoupon?.id);

    if (i == -1) return;

    this.order.coupons.splice(i, 1);
    this.cardCoupon = undefined;

    this.calcTotal();
  }

  async searchCardDiscounts() {
    if (!this.order || this.paymentInfo.card.length !== 16) return;

    try {
      let coupon = await lastValueFrom(
        this.couponService.find(
          this.paymentInfo.card.slice(0, 6),
          this.order.event.id,
          [],
          CouponType.CreditCard,
        ),
      );

      if (typeof coupon == 'string' || !coupon) {
        this.cardCoupon && this.removeCardDiscount();
        return;
      }

      if (!this.order.coupons) this.order.coupons = [];

      if (this.cardCoupon) {
        this.removeCardDiscount();
      }

      this.cardCoupon = coupon;
      this.order.coupons.push(coupon);

      this.calcTotal();

      await Swal.fire({
        html: `Descuento (${coupon.name} -${coupon.discount}%) aplicado correctamente`,
        icon: 'success',
      });
    } catch (e) {
      console.log(e);
    }
  }

  private addStartOnDates(tts: TicketType[]) {
    this.dates = [];

    for (const tt of tts) {
      if (tt.start_on !== null && !this.dates.includes(tt.start_on)) {
        this.dates.push(tt.start_on);
      }
    }

    if (this.dates.length === 0 && this.order?.event) {
      this.dates.push(this.order.event.startAt.toString());
    }

    this.dates.sort();
  }
}
