<div class="d-grid gap-1">
  <div
    style="cursor: pointer"
    (click)="onClickBanner(advertising)"
    *ngFor="let advertising of advertisings"
    class="justify-content-between alert-secondary border border-dark rounded-3 p-2 d-flex gap-3 align-items-center"
    role="alert"
  >
    <div class="d-flex gap-3 align-items-center">
      <figure
        style="
          width: 50px;
          height: 50px;
          margin: 0px;
          overflow: hidden;
          border-radius: 8px;
        "
      >
        <img
          [src]="advertising.mobileUri"
          class="w-100 h-100 object-fit-contain"
        />
      </figure>
      <strong>
        {{ advertising.title }}
      </strong>
    </div>
    <div class="rounded-pill btn btn-sm px-3 py-2 btn-outline-dark">
      Tickets
    </div>
  </div>
</div>
