<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-12 col-md-9">
      <h2 class="my-4 display-6 text-center">Políticas de reembolso</h2>
      <ol class="list-group list-group-numbered">
        <li
          class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
        >
          <div class="ms-2 me-auto">
            El comprador y/o portador de esta boleta electrónica reconoce y
            acepta que TIX.do es el prestador del servicio de boletería y no el
            organizador y/o propietario responsable del evento, y por lo tanto
            desiste y renuncia irrevocablemente, desde ahora y para siempre y
            sin reservas de ningún tipo, a demandas, reclamaciones o instancias
            que pudiere tener en contra de TIX, sus accionistas, directores,
            representantes o empleados, empresas afiliadas, que tengan su
            fundamento o que estén relacionadas directa o indirectamente con el
            evento de cualquier tipo de naturaleza.
          </div>
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
        >
          <div class="ms-2 me-auto">
            En caso de un inconveniente de fuerza mayor que impida la
            realización del evento, la empresa organizadora se reserva el
            derecho de alterar y modificar fecha y lugar, lo cual será
            comunicado por medio de la prensa nacional, correo electrónico o
            Redes Sociales.
          </div>
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
        >
          <div class="ms-2 me-auto">
            TIX no asume responsabilidad alguna sobre la organización del
            evento, su postergación o cancelación, la cual es asumida por el
            titular de esta boleta, quien asume todo riesgo y peligro derivado
            del mismo.
          </div>
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
        >
          <div class="ms-2 me-auto">
            En caso de lluvia, fallas técnicas, enfermedad o cualquier otra
            causa, el evento podría ser pospuesto y si sucediera dicha
            postergación la boleta mantendrá su validez hasta la fijación de una
            nueva fecha, la cual será aceptada por el titular que renuncia a
            toda reclamación por daños y perjuicios por dichos cambios,
            reconociendo que la postergación no dará derecho a reembolso de
            ningún tipo.
          </div>
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
        >
          <div class="ms-2 me-auto">
            En el caso de que el organizador o responsable del evento anule,
            posponga o cancele el evento, será responsabilidad exclusiva del
            mismo la devolución total o parcial del pago, según proceda.
          </div>
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
        >
          <div class="ms-2 me-auto">
            Para cualquier reclamo, cambios, incluyendo las solicitudes de
            reembolso y devoluciones de dinero por compra de boletas, el titular
            se dirigirá directamente al ORGANIZADOR o RESPONSABLE DEL EVENTO.
          </div>
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
        >
          <div class="ms-2 me-auto">
            Esta boleta será válida para la fecha definitiva y de decidirse su
            reembolso por suspensión, se realizará en un tiempo prudente fijado
            por la empresa organizadora, sin ningún tipo de reajuste o
            indemnización, tomando como precio el fijado en el valor de la
            misma.
          </div>
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
        >
          <div class="ms-2 me-auto">
            En caso de cancelación o reembolso, por cualquier motivo de parte de
            la empresa organizadora. Se realizará el reembolso directamente a la
            tarjeta con las se realizó la compra.
          </div>
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
        >
          <div class="ms-2 me-auto">
            En caso de postergación, cancelación o reembolso, por cualquier
            motivo de parte de la empresa organizadora, EL CARGO POR SERVICIO NO
            SERA REEMBOLSADO.
          </div>
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
        >
          <div class="ms-2 me-auto">
            Únicamente se contenplará reembolso bajo las condiciones de: fuerza
            mayor y cancelación del evento. Dichos reembolsos deben ser
            aprobados directamente por el organizador o titutlar del evento.
          </div>
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
        >
          <div class="ms-2 me-auto">
            Reeembolos por confusiones, errores, inasistencia, enfermedad y
            otras causales menores no serán considerados.
          </div>
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
        >
          <div class="ms-2 me-auto">
            En caso de postergación, cancelación o reembolso, por cualquier
            motivo de parte de la empresa organizadora, el cliente deberá
            solicitar su reembolso en un tiempo no mayor a  15 días luego de
            haberse anunciado la cancelación del evento. Pasado los 15 días el
            cliente pierde todo derecho a recibir su reembolso.
          </div>
        </li>
      </ol>
    </div>
  </div>
</div>
