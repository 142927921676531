<main class="d-flex justify-content-between" id="page_checkout">
  <div class="checkout-col-1 bg-white w-100 h-100" style="min-height: 100vh">
    <div class="container-fluid me-lg-0" style="max-width: 660px">
      <div class="row justify-content-center g-3">
        <div class="col-12 col-md-10">
          <header class="pt-3 pb-1">
            <figure class="m-0">
              <img src="assets/img/logo-tix.png" alt="" width="54" />
            </figure>
          </header>
        </div>
        <div class="col-12 col-md-10">
          <div class="nav small mb-4">
            <a
              class="nav-link p-0 fw-light pe-3 link-dark"
              href="/event/{{ order?.event?.slug ?? '' }}"
              >Evento</a
            >
            <span class="nav-link p-0 pe-3 text-muted">&rsaquo;</span>
            <a class="nav-link p-0 pe-3 link-dark active" aria-current="page"
              >Información y pago</a
            >
          </div>
        </div>
      </div>
      <!-- Timer -->
      <div class="row justify-content-center">
        <div
          class="col-12 col-md-10 align-items-center"
          *ngIf="timeoutMinutes >= 0"
        >
          <div class="d-flex">
            <div class="text-muted" style="width: 150px; line-height: normal">
              <small>Tiempo restante para completar tu orden:</small>
            </div>
            <p class="h2 m-0">
              <span class="fw-bold"
                >{{
                  timeoutMinutes >= 10 ? timeoutMinutes : "0" + timeoutMinutes
                }}:{{
                  timeoutSeconds >= 10 ? timeoutSeconds : "0" + timeoutSeconds
                }}</span
              >
            </p>
          </div>
          <hr />
        </div>
      </div>

      <core-loading [message]="'Por favor espere...'"></core-loading>
      <app-return-policies-modal></app-return-policies-modal>
      <app-usage-terms-modal></app-usage-terms-modal>
      <app-three-dschallenge #threeChallengeModal></app-three-dschallenge>
      <app-cybersource-authentication-required-modal></app-cybersource-authentication-required-modal>
      <!-- row -->
      <div class="row justify-content-center d-lg-none">
        <div class="col-12 col-md-10">
          <div class="card text-bg-light mb-lg-4 rounded-input">
            <div
              class="card-body d-flex justify-content-between align-items-center pe-1"
              (click)="toggleSummary()"
            >
              <div class="d-flex">
                <span
                  class="material-symbols-outlined me-2"
                  style="font-size: 22px"
                  >event_seat</span
                >
                <small>{{ getTotalTickets() }} Tickets en tu orden</small>
              </div>
              <div class="d-flex align-items-center">
                <div>
                  <strong> RD${{ this.order?.totalToPay | number }} </strong>
                </div>
                <span class="material-symbols-outlined">{{
                  showSummary ? "expand_less" : "chevron_right"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- row -->
      <div
        [class]="{ 'checkout-resumen-order-lg-hidden': !showSummary }"
        class="checkout-resumen-order top-0"
        style="max-width: 660px"
      >
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <article>
              <div class="card text-bg-light px-3 border-0 pt-4">
                <div class="row gx-4 gy-2 align-items-center">
                  <div class="col-md-auto d-none d-lg-block">
                    <img
                      [src]="getEventImageUrl()"
                      class="img-fluid rounded-start rounded-tix"
                      alt=""
                      width="80"
                    />
                  </div>
                  <div class="col-md-auto">
                    <div class="card-body p-0">
                      <h6 class="card-title mb-1">
                        {{ this.order?.event?.name }}
                      </h6>
                      <p class="card-text m-0" *ngFor="let date of dates">
                        {{ date | dateFormat }}
                      </p>
                      <p class="card-text m-0">
                        {{ this.order?.event?.place }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr />
                  </div>
                </div>
              </div>
            </article>
          </div>

          <div class="col-12 col-md-10">
            <div class="tickets text-bg-light px-3">
              <ul class="list-group gap-3">
                <li
                  class="list-group-item bg-transparent px-0 border-0 rounded-0"
                  *ngFor="let ticketType of this.order?.tickets"
                >
                  <div
                    class="event-ticket-type d-flex align-items-start justify-content-between"
                  >
                    <div class="event-ticket-type ticket-type-information">
                      <h2 class="h6 small mb-1 fw-bold text-uppercase">
                        {{ ticketType.name }}
                      </h2>
                      <data class="amount d-block small" value="1200.00"
                        >{{
                          eventFree ? "" : "RD$" + (ticketType.price | number)
                        }}
                        X {{ ticketType.tickets.length }}</data
                      >
                      <div *ngIf="ticketType.isSeat">
                        <small class="text-muted">Asientos alegidos</small>
                        <ul
                          class="list-group border-start rounded-0 mx-1 mt-2 gap-2"
                        >
                          <li
                            class="list-group-item p-0 bg-transparent border-0 d-flex ps-2"
                            *ngFor="let ticket of ticketType.tickets"
                          >
                            <span
                              class="material-symbols-outlined mx-1"
                              style="font-size: 22px"
                              >event_seat</span
                            >
                            <small class="text-muted">{{ ticket.label }}</small>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="add-ticket-type">
                      <strong>
                        {{
                          eventFree || ticketType.isFree
                            ? "GRATIS"
                            : "RD$" +
                              (ticketType.price * ticketType.tickets.length
                                | number)
                        }}
                      </strong>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <hr />
          </div>

          <ng-container *ngIf="!eventFree">
            <div class="col-12 col-md-10">
              <form action="" class="position-relative text-bg-light px-3">
                <div class="form-floating small">
                  <input
                    type="text"
                    class="form-control border-secondary px-3 rounded-input"
                    name="coupon"
                    style="padding-right: 114px !important"
                    id="offInput"
                    placeholder="Código de descuento"
                    [value]="couponCode"
                    (keyup)="handleCouponCodeChange($event)"
                  />
                  <label for="offInput" class="fw-light px-3"
                    >Código de descuento</label
                  >
                </div>
                <span
                  class="coupon-status-valid"
                  style="text-transform: uppercase"
                  *ngFor="let coupon of codeCoupons"
                  >{{
                    "CUPÓN VÁLIDO POR " +
                      coupon.discount +
                      "% DE DESCUENTO " +
                      getValidTicketTypes(coupon)
                  }}</span
                >
                <span
                  class="coupon-status-invalid"
                  style="text-transform: uppercase"
                  *ngIf="couponError"
                  >{{ couponError }}</span
                >
                <button
                  class="btn btn-dark btn-sm px-4 py-2 rounded-input position-absolute"
                  style="top: 10px; right: 25px"
                  (click)="applyCoupon()"
                  [disabled]="!buttonStatus.coupon"
                >
                  {{ couponButtonLabel }}
                </button>
              </form>
              <hr />
            </div>

            <div class="col-12 col-md-10">
              <ul class="list-group text-bg-light px-3">
                <li
                  class="list-group-item border-0 px-0 bg-transparent justify-content-between d-flex w-100"
                >
                  <span>Subtotal</span>
                  <span class="fw-bold"
                    >RD${{ this.order?.subTotal ?? 0 | number }}</span
                  >
                </li>
                <li
                  class="list-group-item border-0 px-0 bg-transparent justify-content-between d-flex w-100"
                >
                  <span>Cargo por servicio</span>
                  <span class="fw-bold"
                    >RD${{ this.order?.serviceFee ?? 0 | number }}</span
                  >
                </li>
                <li
                  class="list-group-item border-0 px-0 bg-transparent justify-content-between d-flex w-100"
                >
                  <span>Cargo por seguro</span>
                  <span class="fw-bold"
                    >RD${{ this.order?.insuranceFee ?? 0 | number }}</span
                  >
                </li>
                <li
                  *ngIf="cardCoupon"
                  class="list-group-item border-0 px-0 bg-transparent justify-content-between d-flex w-100"
                >
                  <div class="d-flex align-items-center gap-1">
                    <span>Descuento ({{ this.cardCoupon?.name }}</span>
                    <span> -{{ this.cardCoupon?.discount }}%)</span>
                  </div>
                  <span class="fw-bold coupon-amount"
                    >-RD${{ this.cardCoupon?.discounted | number }}</span
                  >
                </li>
                <li
                  class="list-group-item border-0 px-0 bg-transparent justify-content-between d-flex w-100"
                  *ngIf="codeCoupons.length"
                >
                  <span>Cupón ({{ couponsNames }}) aplicado</span>
                  <span class="fw-bold coupon-amount"
                    >-RD${{ codeCouponsDiscount | number }}</span
                  >
                </li>
                <hr />
                <li
                  class="list-group-item border-0 px-0 align-items-center bg-transparent justify-content-between d-flex w-100"
                >
                  <span>Total a pagar</span>
                  <span class="fw-bold h5 m-0"
                    >RD${{ this.order?.totalToPay ?? 0 | number }}</span
                  >
                </li>
                <hr />
              </ul>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- row -->
      <div class="row justify-content-center g-4 pt-3">
        <div class="col-12 col-md-10">
          <h3 class="h5 fw-bold mb-3">Datos de contacto</h3>
          <div class="form-floating small mb-2">
            <input
              type="text"
              class="form-control border-secondary px-3 rounded-input"
              id="nameInput"
              name="nameInput"
              placeholder="Nombre completo"
              [(ngModel)]="buyerData.name"
            />
            <label for="nameInput" class="fw-light px-3">Nombre completo</label>
          </div>
          <div class="form-floating small">
            <input
              type="email"
              class="form-control border-secondary px-3 rounded-input"
              [class]="{ 'is-invalid': isEmailInvalid }"
              id="emailInput"
              name="emailInput"
              placeholder="nombre@correo.com"
              autocomplete="off"
              [formControl]="emailInput"
              required
            />
            <label for="emailInput" class="fw-light px-3"
              >Correo electrónico</label
            >
            <div class="invalid-feedback">Este correo no es válido</div>
          </div>
        </div>

        <ng-container *ngIf="!eventFree">
          <div class="col-12 col-md-10">
            <h3 class="h5 fw-bold mb-3">Asegura tu compra</h3>
            <div
              class="btn-group w-100"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                class="btn-check"
                name="btnradio"
                id="btnradio1"
                autocomplete="off"
                checked
                (change)="applyInsuranceFee()"
              />
              <label
                class="btn border rounded-input border-secondary btn-outline-dark py-4 w-50 d-grid justify-content-center align-items-center"
                for="btnradio1"
              >
                <span class="d-block h5 mb-0 fw-normal"
                  >RD${{ insuranceFee | number }}</span
                >
                <span class="small text-muted">Quiero asegurar mi compra</span>
              </label>

              <input
                type="radio"
                class="btn-check"
                name="btnradio"
                id="btnradio2"
                autocomplete="off"
                (change)="removeInsuranceFee()"
              />
              <label
                class="btn border rounded-input border-secondary btn-outline-dark py-4 w-50 d-flex justify-content-center align-items-center"
                for="btnradio2"
              >
                <span class="d-block h5 mb-0 fw-normal">No, gracias</span>
              </label>
            </div>
            <p class="small text-muted mt-2 mb-0">
              Obtén el 100% del valor de tus entradas en caso de que no puedas
              asistir al evento. Ciertas condiciones aplican
            </p>
          </div>

          <div class="col-12 col-md-10">
            <h3 class="h5 fw-bold mb-3">Información de pago</h3>
            <ul class="list-group rounded-input">
              <li
                class="list-group-item p-0 border-secondary"
                style="overflow: hidden"
              >
                <header class="d-flex p-3 justify-content-between">
                  <h6
                    class="form-check-label w-100 d-flex gap-1 align-items-center m-0"
                    for="secondRadio"
                  >
                    Pago seguro con
                    <img
                      [src]="
                        '../../../../../assets/img/' + gateway + 'Logo.png'
                      "
                      height="24px"
                      alt=""
                    />
                  </h6>
                  <div class="iconspay d-flex gap-1">
                    <svg
                      viewBox="0 0 38 24"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      width="38"
                      height="24"
                      aria-labelledby="pi-visa"
                    >
                      <title id="pi-visa">Visa</title>
                      <path
                        opacity=".07"
                        d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
                      ></path>
                      <path
                        fill="#fff"
                        d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
                      ></path>
                      <path
                        d="M28.3 10.1H28c-.4 1-.7 1.5-1 3h1.9c-.3-1.5-.3-2.2-.6-3zm2.9 5.9h-1.7c-.1 0-.1 0-.2-.1l-.2-.9-.1-.2h-2.4c-.1 0-.2 0-.2.2l-.3.9c0 .1-.1.1-.1.1h-2.1l.2-.5L27 8.7c0-.5.3-.7.8-.7h1.5c.1 0 .2 0 .2.2l1.4 6.5c.1.4.2.7.2 1.1.1.1.1.1.1.2zm-13.4-.3l.4-1.8c.1 0 .2.1.2.1.7.3 1.4.5 2.1.4.2 0 .5-.1.7-.2.5-.2.5-.7.1-1.1-.2-.2-.5-.3-.8-.5-.4-.2-.8-.4-1.1-.7-1.2-1-.8-2.4-.1-3.1.6-.4.9-.8 1.7-.8 1.2 0 2.5 0 3.1.2h.1c-.1.6-.2 1.1-.4 1.7-.5-.2-1-.4-1.5-.4-.3 0-.6 0-.9.1-.2 0-.3.1-.4.2-.2.2-.2.5 0 .7l.5.4c.4.2.8.4 1.1.6.5.3 1 .8 1.1 1.4.2.9-.1 1.7-.9 2.3-.5.4-.7.6-1.4.6-1.4 0-2.5.1-3.4-.2-.1.2-.1.2-.2.1zm-3.5.3c.1-.7.1-.7.2-1 .5-2.2 1-4.5 1.4-6.7.1-.2.1-.3.3-.3H18c-.2 1.2-.4 2.1-.7 3.2-.3 1.5-.6 3-1 4.5 0 .2-.1.2-.3.2M5 8.2c0-.1.2-.2.3-.2h3.4c.5 0 .9.3 1 .8l.9 4.4c0 .1 0 .1.1.2 0-.1.1-.1.1-.1l2.1-5.1c-.1-.1 0-.2.1-.2h2.1c0 .1 0 .1-.1.2l-3.1 7.3c-.1.2-.1.3-.2.4-.1.1-.3 0-.5 0H9.7c-.1 0-.2 0-.2-.2L7.9 9.5c-.2-.2-.5-.5-.9-.6-.6-.3-1.7-.5-1.9-.5L5 8.2z"
                        fill="#142688"
                      ></path>
                    </svg>
                    <svg
                      viewBox="0 0 38 24"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      width="38"
                      height="24"
                      aria-labelledby="pi-master"
                    >
                      <title id="pi-master">Mastercard</title>
                      <path
                        opacity=".07"
                        d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
                      ></path>
                      <path
                        fill="#fff"
                        d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
                      ></path>
                      <circle fill="#EB001B" cx="15" cy="12" r="7"></circle>
                      <circle fill="#F79E1B" cx="23" cy="12" r="7"></circle>
                      <path
                        fill="#FF5F00"
                        d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"
                      ></path>
                    </svg>
                    <img
                      src="/assets/img/mastercard-id-check.jpeg"
                      alt="mastercard_check"
                      style="width: 36px; height: 22px; object-fit: contain"
                    />
                    <img
                      src="/assets/img/visa-secure-blu-2021.png"
                      alt="visa_check"
                      style="width: 36px; height: 22px; object-fit: contain"
                    />
                  </div>
                </header>
                <div
                  class="card border-0 rounded-0 border-top"
                  style="background-color: #efefef"
                >
                  <div class="card-body">
                    <div class="row g-2 gx-3">
                      <div class="col-12">
                        <div class="form-floating small mb-2">
                          <input
                            type="text"
                            class="form-control border-secondary px-3 rounded-input"
                            id="cardNumberName"
                            placeholder="Número de tarjeta"
                            [(ngModel)]="paymentInfo.name"
                          />
                          <label for="cardNumberInput" class="fw-light px-3"
                            >Nombre titular tarjeta</label
                          >
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-floating small mb-2">
                          <input
                            type="text"
                            mask="0000 0000 0000 0000"
                            class="form-control border-secondary px-3 rounded-input"
                            id="cardNumberInput"
                            (focusout)="searchCardDiscounts()"
                            placeholder="Número de tarjeta"
                            [(ngModel)]="paymentInfo.card"
                          />
                          <label for="cardNumberInput" class="fw-light px-3"
                            >Número de tarjeta</label
                          >
                        </div>
                      </div>
                      <div class="col-7">
                        <div class="form-floating small mb-2">
                          <input
                            type="text"
                            class="form-control border-secondary px-3 rounded-input"
                            id="expInput"
                            mask="00/00"
                            placeholder="Fecha de expiración (MM/AA)"
                            [(ngModel)]="paymentInfo.expDate"
                          />
                          <label for="expInput" class="fw-light px-3"
                            >Fecha de expiración (MM/AA)</label
                          >
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="form-floating small mb-2">
                          <input
                            mask="000"
                            class="form-control border-secondary px-3 rounded-input ccv_input"
                            id="cvvInput"
                            placeholder="Nombre completo"
                            [(ngModel)]="paymentInfo.cvc"
                          />
                          <label for="cvvInput" class="fw-light px-3"
                            >(CVV) Código de seguridad</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </ng-container>

        <div class="col-12 col-md-10">
          <div class="grid-flex nav justify-content-between">
            <button
              class="btn bg-transparent border-0 text-muted py-2 btn-light rounded-pill link-dark"
              (click)="back()"
            >
              Regresar
            </button>
            <button
              class="btn px-5 py-2 btn-dark fw-bold rounded-pill"
              (click)="proceedPayment()"
              [disabled]="!buttonStatus.checkout || !isFormValid()"
            >
              {{ eventFree ? "Recibir tickets" : "Pagar ahora" }}
            </button>
          </div>
        </div>

        <div class="col-12 col-md-10 mt-5">
          <p class="w-100 fs-6">
            Al realizar esta compra usted acepta los términos y condiciones
          </p>
          <hr />
          <div class="nav small">
            <button
              data-bs-toggle="modal"
              data-bs-target="#returnPolicies"
              class="btn p-0 py-2 pe-3 link-dark"
            >
              Políticas de devolución
            </button>
            <button
              data-bs-toggle="modal"
              data-bs-target="#usageTerms"
              class="btn p-0 py-2 pe-3 link-dark"
            >
              Términos de uso
            </button>
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
  </div>
  <div class="checkout-col-2 w-100">
    <div class="container-fluid ms-0"></div>
  </div>
</main>
<div id="3dscontainer" [innerHtml]="threeDSForm"></div>

<iframe
  id="cardinal_collection_iframe"
  name="collectionIframe"
  height="10"
  width="10"
  style="display: none"
></iframe>
<form id="cardinal_collection_form" method="POST" target="collectionIframe">
  <input id="cardinal_collection_form_input" type="hidden" name="JWT" />
</form>

<form id="step-up-form" target="step-up-iframe" method="post">
  <input id="step-up-form-input" type="hidden" name="JWT" />
  <input id="step-up-form-input-reference-id" type="hidden" name="MD" />
</form>

<button
  id="purchaseAuthenticationBtn"
  data-bs-toggle="modal"
  data-bs-target="#purchaseAuthentication"
  class="btn p-0 py-2 pe-3 link-dark"
></button>
