import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-insurance',
  templateUrl: './order-insurance.component.html',
  styleUrls: ['./order-insurance.component.css'],
})
export class OrderInsuranceComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
