import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DetailService {
  private _refreshTicketTypes = new Subject<void>();
  onRefreshTicketTypes = this._refreshTicketTypes.asObservable();

  refreshTicketTypes() {
    this._refreshTicketTypes.next();
  }
}
