import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './layout/components/header/header.component';
import { FooterComponent } from './layout/components/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { CheckoutOutletComponent } from './outlets/checkout.outlet/checkout.outlet.component';
import { PrimaryOutletComponent } from './outlets/primary.outlet/primary.outlet.component';
import { LoadingComponent } from './components/loading/loading.component';
import { EventItemComponent } from './layout/components/header/components/event-item/event-item.component';
import { FormsModule } from '@angular/forms';
import { FormInputDirective } from './directives/form-input.directive';
import { NotAvailableComponent } from './components/not-available/not-available.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    PrimaryOutletComponent,
    CheckoutOutletComponent,
    LoadingComponent,
    EventItemComponent,
    FormInputDirective,
    NotAvailableComponent,
  ],
  imports: [CommonModule, RouterOutlet, FormsModule],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    FormInputDirective,
    NotAvailableComponent,
  ],
})
export class CoreModule {}
