import { Injectable } from '@angular/core';
import { BaseHttpService } from './base.http.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TixConfigurationService extends BaseHttpService {
  constructor(http: HttpClient) {
    super(http);
  }

  getVendorRegisterUrl(): Observable<string> {
    const url = `${this.getApiBaseUrl()}/tix-configuration`;

    return this.http.get(url).pipe(
      map((resp: any) => {
        return resp.data.attributes.vendor_register_url;
      }),
      catchError(() => {
        return of('');
      }),
    );
  }
}
