import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../../core/services/storage.service';
import Order from '../../../../core/models/order';
import { Constants } from '../../../../core/utils/constants';
import { UtilService } from '../../../../core/services/util.service';
import * as moment from 'moment';
import { OrderService } from '../../../order/services/order.service';
import CouponType from '../../../../core/enums/couponType.enum';
import TicketType from 'src/app/core/models/ticket.type';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css'],
})
export class ConfirmationComponent implements OnInit {
  public order: Order | null = null;
  dates: string[] = [];

  get discounted() {
    return this.orderService.codeCouponsDiscount(this.order);
  }

  get couponsNames() {
    return this.codeCoupons.map((c) => c.code);
  }

  get codeCoupons() {
    return this.order?.coupons?.filter((c) => c.type == CouponType.Code) || [];
  }

  get cardCoupon() {
    return this.order?.coupons?.find((c) => c.type == CouponType.CreditCard);
  }

  constructor(
    private localStorageService: StorageService,
    private utilService: UtilService,
    private orderService: OrderService,
  ) {}

  ngOnInit(): void {
    this.order =
      JSON.parse(
        this.localStorageService.get(Constants.Keys.OrderConfirmation) ?? '',
      ) ?? null;
    if (this.order) {
      const createdAt = moment(this.order.createdAt!);

      this.order.createdAt = createdAt.toDate();

      const tickets = this.order.tickets;

      tickets.forEach((ticket) => {
        const tt = ticket.tickets[0].ticket_type as TicketType;

        if (tt.start_on && !this.dates.includes(tt.start_on)) {
          this.dates.push(tt.start_on);
        }

        this.dates = this.dates.sort();

        if (this.dates.length === 0 && this.order?.event) {
          this.dates.push(this.order.event.startAt as any);
        }
      });
    }
  }

  getImageUrl() {
    return this.utilService.getAbsoluteUrl(this.order?.event?.coverUri ?? '');
  }
}
