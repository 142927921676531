import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ListComponent } from './pages/list/list.component';
import { FilterComponent } from './components/filter/filter.component';
import { CardComponent } from './components/card/card.component';
import { DetailComponent } from './pages/detail/detail.component';
import { RouterModule } from '@angular/router';
import { VendorComponent } from './components/vendor/vendor.component';
import { TicketComponent } from './components/ticket/ticket.component';
import { UnavailableButtonComponent } from './components/ticket-buttons/unavailable-button/unavailable-button.component';
import { SeatPickerButtonComponent } from './components/ticket-buttons/seat-picker-button/seat-picker-button.component';
import { SelectorButtonComponent } from './components/ticket-buttons/selector-button/selector-button.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { FilterByCategoryPipe } from './pipes/filter.by.category.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from 'src/app/core/core.module';
import { ShareModule } from '../../share/share.module';
import { UsageTermsModalComponent } from './components/usage-terms-modal/usage-terms-modal.component';
import { ReturnPoliciesModalComponent } from './components/return-policies-modal/return-policies-modal.component';
import { UsageTermsContentComponent } from './components/usage-terms-content/usage-terms-content.component';
import { ReturnPoliciesContentComponent } from './components/return-policies-content/return-policies-content.component';
import { NgxMaskModule } from 'ngx-mask';
import { ThreeDSChallengeComponent } from './pages/checkout/three-dschallenge/three-dschallenge.component';
import { ChallengeCompletedComponent } from './pages/checkout/challenge-completed/challenge-completed.component';
import { AdvertisingListComponent } from './pages/advertising-list/advertising-list.component';
import { DetailSkeletonComponent } from './pages/detail/skeleton/detail-skeleton/detail-skeleton.component';
import { PlayVideoComponent } from '../../play-video/play-video.component';
import { CybersourceAuthenticationRequiredModalComponent } from './components/cybersource-authentication-required-modal/cybersource-authentication-required-modal.component';

@NgModule({
  declarations: [
    CarouselComponent,
    ListComponent,
    FilterComponent,
    CardComponent,
    DetailSkeletonComponent,
    DetailComponent,
    VendorComponent,
    TicketComponent,
    UnavailableButtonComponent,
    SeatPickerButtonComponent,
    SelectorButtonComponent,
    CheckoutComponent,
    ConfirmationComponent,
    FilterByCategoryPipe,
    UsageTermsModalComponent,
    ReturnPoliciesModalComponent,
    UsageTermsContentComponent,
    ReturnPoliciesContentComponent,
    ThreeDSChallengeComponent,
    ChallengeCompletedComponent,
    AdvertisingListComponent,
    CybersourceAuthenticationRequiredModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CoreModule,
    ShareModule,
    ReactiveFormsModule,
    PlayVideoComponent,
    NgxMaskModule.forRoot(),
  ],
  exports: [UsageTermsContentComponent, ReturnPoliciesContentComponent],
})
export class EventsModule {}
