<section>
  <header class="d-flex align-items-center m-3" style="min-height: 100px">
    <div class="container-fluid my-2">
      <div class="row justify-content-center">
        <div class="col-auto text-uppercase text-center">
          <h1 class="display-1 fw-bold mb-1" style="letter-spacing: -1px">
            Seguro
          </h1>
          <p class="text-secoundary" style="max-width: 800px">
            "Es mejor tenerlo y no usarlo, que no teneerlo y necesitarlo"
          </p>
        </div>
      </div>
    </div>
  </header>
  <div class="position-relative py-3">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-md-9">
          <p>
            Con Tix puedes asegurar tu compra y recibir el reembolso del 100% de
            tu orden, en caso de que no puedas asistir al evento por alguna de
            las siguientes razones:
          </p>
          <p>
            *(Recuerda que debes elegir la opción "<strong>
              Asegura tu compra </strong
            >" al momento de realizar tu compra)
          </p>
          <ul class="list-group my-4">
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">Enfermedad / Lesión</div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">Accidente</div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">Situaciones de emergencia</div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">Condición Médica Preexistente</div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">Complicación del Embarazo</div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">Muerte de un Familiar Inmediato</div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">
                Fallo del Transporte Público o Privado
              </div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">Emergencia Domiciliaria</div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">Robo de Documentos</div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">Reubicado por Trabajo</div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">
                Retiro de las Fuerzas Armadas y Servicios de Emergencia
              </div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">Citación Judicial</div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">
                Cambios en las Fechas de los Exámenes
              </div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">Interupción del vuelo</div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">Avería Mecánica</div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">Clima Adverso</div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-4 py-3"
            >
              <div class="ms-2 me-auto">Incendios / Inundaciones</div>
            </li>
          </ul>
          <p>
            Podemos considerar
            <strong> otras circunstancias de emergencia </strong> a nuestra
            absoluta discreción.
          </p>
          <p>
            **Deberá usted proporcionar <strong> evidencia </strong> de
            cualquiera de estas circunstancias para poder obtar por el
            reembolso.
          </p>
          <p>
            De su reembolso ser aprobado, el mismo será realizado a la tarjeta
            con la cual realizo la compra en un plazo no mayor a 5 días
            laborables.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
