import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import TicketType from '../../../../../core/models/ticket.type';
import Ticket from '../../../../../core/models/ticket';
import { TicketStatus } from '../../../../../core/models/ticket.status';

@Component({
  selector: 'selector-button',
  templateUrl: './selector-button.component.html',
  styleUrls: ['./selector-button.component.css'],
})
export class SelectorButtonComponent {
  @Input() ticketType: TicketType | null = null;
  @Output() ticketEventEmitter = new EventEmitter<
    TicketType & { action?: 'remove-all' }
  >();

  usedTickets = 0;

  get selected() {
    if (!this.ticketType) return 0;

    const result = this.ticketType.tickets.reduce((count, t) => {
      if (t.status == this.statusUsed) count += 1;

      return count;
    }, 0);

    return result;
  }

  public readonly statusAvailable = TicketStatus.AVAILABLE;
  public readonly statusUsed = TicketStatus.USED;

  isTicketLimitReached(): boolean {
    if (this.ticketType) {
      return (
        this.ticketType.tickets.filter(
          (ticket, i) => ticket.status === this.statusAvailable,
        ).length === 0
      );
    }
    return true;
  }

  toggleTicket(fromStatus: TicketStatus, toStatus: TicketStatus): void {
    let ticketsToSelect = 1;

    if (
      fromStatus == TicketStatus.AVAILABLE &&
      this.usedTickets <= this.ticketType!.max_tickets_per_order
    ) {
      if (this.usedTickets < this.ticketType!.min_tickets_per_order) {
        this.usedTickets = this.ticketType!.min_tickets_per_order;
        ticketsToSelect = this.ticketType!.min_tickets_per_order;
      } else {
        this.usedTickets += 1;
      }
    }

    if (fromStatus == TicketStatus.USED) {
      if (this.usedTickets == this.ticketType!.min_tickets_per_order) {
        alert(
          'Mínimo de tickets por orden: ' +
            this.ticketType!.min_tickets_per_order,
        );
        return;
      }

      this.usedTickets -= 1;
    }

    if (this.ticketType) {
      if (
        fromStatus == TicketStatus.AVAILABLE &&
        this.usedTickets > this.ticketType!.max_tickets_per_order
      ) {
        this.usedTickets -= 1;
        alert(
          'Tickets máximos por orden: ' +
            this.ticketType!.max_tickets_per_order,
        );
        return;
      }

      for (let i = 0; i < ticketsToSelect; i++) {
        const _ticket = this.ticketType.tickets.find(
          (t: Ticket) => t.status === fromStatus,
        );

        if (_ticket) {
          _ticket.status = toStatus;
          this.ticketEventEmitter.emit(this.ticketType);
        }
      }
    }
  }

  removeSelection() {
    this.ticketType!.tickets.forEach((t: Ticket) => {
      if (t.status == TicketStatus.USED) {
        t.status = TicketStatus.AVAILABLE;
      }
    });

    this.usedTickets = 0;

    this.ticketEventEmitter.emit({ ...this.ticketType!, action: 'remove-all' });
  }
}
