<section *ngIf="banners.length">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <event-carousel
          [sectionId]="'event_list'"
          [banners]="banners"
        ></event-carousel>
      </div>
    </div>
  </div>
</section>
<section *ngIf="advertings.length">
  <div class="container-fluid mt-2">
    <div class="row">
      <div class="col">
        <app-advertising-list [banners]="advertings"></app-advertising-list>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container-fluid">
    <event-filter
      (categoryDispatcher)="onEventCategoryChanged($event)"
      (queryDispatcher)="onQueryCategory($event)"
    ></event-filter>

    <div
      class="row z-1 position-relative row-cols-2 row-cols-md-3 row-cols-lg-4 g-2 g-sm-3 g-md-3 g-xl-4"
    >
      <ng-container *ngIf="loading; else eventsList">
        <div
          class="col placeholder-glow"
          *ngFor="let placeholder of placeholders"
        >
          <div class="text-dark z-1 link-card-event" style="cursor: pointer">
            <div class="card card-event border-dark border-0 rounded-tix">
              <div
                class="card-img-top rounded-tix placeholder col-12"
                style="height: 280px"
              ></div>
              <div
                class="card-body border rounded position-relative border-dark rounded-tix"
                style="margin-top: -40px; background-color: white"
              >
                <div class="card-price d-flex mb-3 aling-items-center">
                  <data
                    class="amount me-1 small placeholder col-2 rounded-tix"
                  ></data>
                  <data
                    class="amount ms-1 small text-truncate placeholder col-2 rounded-tix"
                  ></data>
                </div>
                <h3
                  class="card-title h6 m-0 placeholder col-4 rounded-tix"
                ></h3>
                <ul class="list-group my-3">
                  <li
                    class="list-group-item p-0 border-0 aling-items-center d-flex text-muted mb-2"
                  >
                    <small
                      class="text-truncate placeholder col-6 rounded-tix"
                    ></small>
                  </li>
                  <li
                    class="list-group-item p-0 border-0 aling-items-center d-flex text-muted"
                  >
                    <small
                      class="text-truncate placeholder col-6 rounded-tix"
                    ></small>
                  </li>
                </ul>
                <div
                  class="action_button btn btn-outline-dark rounded-pill w-100 fw-semibold placeholder col-8"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #eventsList>
        <ng-container
          *ngIf="
            (events | filterByCategory: categorySelected).length > 0;
            else noEvents
          "
        >
          <div
            class="col"
            *ngFor="let event of events | filterByCategory: categorySelected"
          >
            <event-card [event]="event"></event-card>
          </div>
        </ng-container>
      </ng-template>
    </div>

    <ng-template #noEvents>
      <div class="row px-5 mx-auto my-4 justify-content-center">
        No se ha encontrado ningún evento en esta categoría.
      </div>
    </ng-template>
  </div>
</section>
