import { Injectable } from '@angular/core';
import { BaseHttpService } from '../../../core/services/base.http.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import Coupon from '../../../core/models/coupon';
import CouponType from '../../../core/enums/couponType.enum';

@Injectable({
  providedIn: 'root',
})
export class CouponService extends BaseHttpService {
  constructor(http: HttpClient) {
    super(http);
  }

  find(
    code: string,
    eventId: number,
    ticketTypesIds: number[],
    type: CouponType = CouponType.Code,
  ): Observable<Coupon | null> {
    const url = `${this.getApiBaseUrl()}/coupons/validate/${code}/${type}?eventId=${eventId}&ticketTypes=${JSON.stringify(
      ticketTypesIds,
    )}`;

    return this.http.get(url).pipe(
      map((resp: any) => {
        const _couponData = resp.data[0];
        return {
          id: _couponData.id,
          name: _couponData.attributes.name,
          quantity: _couponData.attributes.quantity,
          code: _couponData.attributes.code,
          discount: _couponData.attributes.discount,
          type: _couponData.attributes.type,
          ticket_types: _couponData.attributes.ticket_types?.data.map(
            (tt: any) => ({
              id: tt.id,
              ...tt.attributes,
            }),
          ),
        };
      }),
      catchError((e) => {
        console.log(e);
        return of(e.error?.error?.message || null);
      }),
    );
  }
}
