import { Injectable, OnInit } from '@angular/core';
import { EventService } from './event.service';
import Event from '../../../core/models/event';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  public _events: Event[] = [];

  fetched = new BehaviorSubject<undefined>(undefined);
  loading = true;

  constructor(private eventService: EventService) {
    lastValueFrom(this.eventService.getDashboardEvents()).then(
      (resp: Event[]) => {
        this._events = resp.sort((event) => (event.hasAdd ? -1 : 1)); // Immutable event list
        this.fetched.next(undefined);
        this.loading = false;
      },
    );
  }
}
