import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  encrypt(data: any) {
    const dataToEncrypt = JSON.stringify(data);

    const encryptedValue = CryptoJS.AES.encrypt(
      dataToEncrypt,
      environment.encryptionKey,
    ).toString();

    return encryptedValue;
  }

  decrypt(data: string) {
    var bytes = CryptoJS.AES.decrypt(data, environment.encryptionKey);

    var originalText = bytes.toString(CryptoJS.enc.Utf8);

    const jsonObject = JSON.parse(originalText);

    return jsonObject;
  }
}
