<div class="container-fluid my-3" style="max-width: 990px">
  <div class="row">
    <div class="col-12 col-md-8 order-md-2 placeholder-glow">
      <ul class="list-group rounded-0 border-0 rounded-tix text-dark">
        <li
          class="list-group-item border-0 border-bottom text-muted d-flex justify-content-between align-items-center py-3"
          *ngFor="let placeholder of placeholders | slice: 0 : 3"
        >
          <div class="placeholder col rounded-tix"></div>
        </li>
      </ul>
    </div>

    <div class="col order-md-1 placeholder-glow">
      <div
        class="card border-0 rounded rounded-pill shadow-sm bg-transparent rounded-tix small"
        style="overflow: hidden"
      >
        <div
          class="card-header bg-white border-bottom rounded-tix py-3"
          style="
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          "
        >
          <div class="m-0 h6 placeholder w-100 rounded-tix">-</div>
        </div>
        <div class="card-body p-0">
          <ul class="list-group rounded-0 border-0 rounded-tix text-dark">
            <li
              class="list-group-item border-0 border-bottom text-muted d-flex justify-content-between align-items-center py-3"
              *ngFor="let placeholder of placeholders"
            >
              <div class="placeholder w-100 rounded-tix"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
