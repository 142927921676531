import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { EventService } from '../../services/event.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../../core/services/storage.service';
import { Constants } from '../../../../core/utils/constants';
import Banner from '../../../../core/models/banner';
import TicketType from '../../../../core/models/ticket.type';
import { TicketStatus } from '../../../../core/models/ticket.status';
import Order from '../../../../core/models/order';
import Ticket from '../../../../core/models/ticket';
import { UtilService } from '../../../../core/services/util.service';
import { BaseEventComponent } from '../../components/base-event/base-event.component';
import {
  ResponseTicketTypeOrGroupedByDate,
  TicketService,
} from '../../services/ticket.service';
import TicketVerificationDto from '../../../../core/dtos/ticket-verification-dto';
import SendTicketVerificationDto from '../../../../core/dtos/send-ticket-verification-dto';
import { lastValueFrom, Subscription } from 'rxjs';
import { AnalitycsService } from '../../../../core/services/analitycs.service';
import { DetailService } from './services/detail.service';
import { LoadingComponent } from '../../../../core/components/loading/loading.component';
import { Fancybox } from '@fancyapps/ui';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css'],
  providers: [DetailService],
})
export class DetailComponent extends BaseEventComponent implements OnInit {
  @ViewChild(LoadingComponent) loadingComponent!: LoadingComponent;

  public totalAmount: number = 0;
  selectedTicketsQuantity = 0;
  public selectedTicketTypeList: TicketType[] = [];
  public proceeding: boolean = false;
  public copyMessage = 'Copiar';
  public copyIcon = 'content_copy';
  public soonerTTDate = '';
  public soonerTTs: TicketType[] = [];

  private refreshSubscription?: Subscription;

  constructor(
    private router: Router,
    private utilService: UtilService,
    eventService: EventService,
    route: ActivatedRoute,
    localStorageService: StorageService,
    private analyticsService: AnalitycsService,
    private detailService: DetailService,
    private ticketsService: TicketService,
  ) {
    super(eventService, localStorageService, route);
  }

  ngOnInit(): void {
    if (this.isMobileDevice()) {
      this.copyMessage = 'Compartir';
      this.copyIcon = 'share';
    } else {
      this.copyMessage = 'Copiar';
    }
  }

  get eventUrl() {
    return window.location.href;
  }

  isMobileDevice() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4),
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || (window as any).opera);
    return check;
  }

  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);

    this.copyIcon = 'check_circle';

    const reset = (msg: string) => {
      setTimeout(() => {
        this.copyMessage = msg;
        this.copyIcon = 'content_copy';
      }, 3000);
    };

    if (this.isMobileDevice()) {
      this.copyMessage = 'Compartido!';
      navigator
        .share({
          title: document.title,
          text: '¡Echa un vistazo a esto!',
          url: window.location.href,
        })
        .then(() => reset('Compartir'))
        .catch((error) => console.log('Error sharing:', error));
    } else {
      this.copyMessage = 'Copiado!';
      reset('Copiar');
    }
  }

  override onInit(): void {
    this.proceeding = false;
    this.totalAmount = 0;
    this.selectedTicketTypeList = [];

    const promoterSlug = this.route.snapshot.queryParamMap.get('promoter');

    this.banners = this.getBanners();

    this.refreshSubscription =
      this.detailService.onRefreshTicketTypes.subscribe(
        this.refreshTicketTypes.bind(this),
      );

    this.eventService.ticketService
      .getTicketType(this.event?.id ?? 0)
      .subscribe((resp: ResponseTicketTypeOrGroupedByDate) => {
        this.ticketTypeList = resp;

        this.listenTicketTypesHold();
        this.listenTicketTypesUnhold();
      });

    if (this.event?.id) {
      if (promoterSlug) {
        sessionStorage.setItem(
          Constants.Keys.Promoter,
          JSON.stringify({
            event: this.event.id,
            promoter: promoterSlug,
          }),
        );
      }

      const startAt = this.event?.startAt.toString();
      if (startAt) {
        this.soonerTTDate = startAt;
      }

      lastValueFrom(
        this.analyticsService.logEventVisit({
          data: {
            type: 'event',
            item_id: this.event.id,
          },
        }),
      );
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();

    this.refreshSubscription?.unsubscribe();

    let list: TicketType[] = this.flatIfIsGroupedByDate();

    list.forEach((tt) => {
      const holdEvent = `ticket_type_holded_out_${tt.id}`;
      const unholdEvent = `ticket_type_unhold_${tt.id}`;

      const socket = this.utilService.getSocket();
      socket.removeAllListeners(holdEvent);
      socket.removeAllListeners(unholdEvent);
    });
  }

  private flatIfIsGroupedByDate() {
    const isGroupedByDate = !Array.isArray(this.ticketTypeList);
    let list: TicketType[];

    if (isGroupedByDate) {
      list = Object.values(this.ticketTypeList).flat();
    } else {
      list = this.ticketTypeList as TicketType[];
    }

    return list;
  }

  private async refreshTicketTypes() {
    if (this.proceeding) return;

    this.loadingComponent.toggle('Refrescando disponibilidad de tickets');

    await new Promise((resolve, reject) => {
      setTimeout(resolve, 1000);
    });

    this.eventService.ticketService
      .getTicketType(this.event?.id ?? 0)
      .subscribe((resp: ResponseTicketTypeOrGroupedByDate) => {
        this.loadingComponent.toggle();

        this.ticketTypeList = resp;

        this.selectedTicketTypeList.forEach((tt) => {
          const ticketType = this.flatIfIsGroupedByDate().find(
            (t) => t.id == tt.id,
          );

          const used = JSON.parse(JSON.stringify(tt.tickets)).filter(
            (t: any) => t.status == 'used',
          );

          const available = JSON.parse(
            JSON.stringify(ticketType!.tickets),
          ).filter((t: any) => t.status == 'available');

          const usedLength =
            available.length >= used.length ? used.length : available.length;

          for (let i = 0; i < usedLength; i++) {
            let usedTicket = used[i];

            let index = 0;

            if (ticketType!.isSeat) {
              index = ticketType!.tickets.findIndex(
                (t) =>
                  t.id == usedTicket.id && t.status == TicketStatus.AVAILABLE,
              );
            } else {
              index = ticketType!.tickets.findIndex(
                (t) => t.status == TicketStatus.AVAILABLE,
              );
            }

            if (index != -1) ticketType!.tickets[index] = usedTicket;
          }
        });
        this.listenTicketTypesUnhold();
      });
  }

  private listenTicketTypesUnhold() {
    this.flatIfIsGroupedByDate().forEach((tt) => {
      const available = tt.tickets.reduce((count, t) => {
        if (t.status == 'available') count += 1;

        return count;
      }, 0);

      if (!available) {
        const socket = this.utilService.getSocket();
        const event = `ticket_type_unhold_${tt.id}`;

        socket.removeAllListeners(event);
        socket.on(event, this.refreshTicketTypes.bind(this));
      }
    });
  }
  private listenTicketTypesHold() {
    this.flatIfIsGroupedByDate().forEach((tt) => {
      const event = `ticket_type_holded_out_${tt.id}`;
      const socket = this.utilService.getSocket();

      socket.removeAllListeners(event);
      socket.on(event, this.refreshTicketTypes.bind(this));
    });
  }

  onTicketChangedListener(
    ticketType: TicketType & { action?: 'remove-all' },
  ): void {
    const index = this.selectedTicketTypeList.findIndex(
      (tt) => tt.id == ticketType.id,
    );

    if (index === -1) {
      this.selectedTicketTypeList.push(ticketType);
    } else {
      this.selectedTicketTypeList[index] = ticketType;
    }

    if (ticketType.action === 'remove-all') {
      this.soonerTTs = this.soonerTTs.filter((x) => x.id !== ticketType.id);
    } else {
      this.soonerTTs.push(ticketType);
    }

    const soonerTT = this.getSoonerTicketType(this.soonerTTs);

    if (soonerTT) {
      this.soonerTTDate = soonerTT.start_on;
    } else {
      this.soonerTTDate = this.event?.startAt.toString() || '';
    }

    this.calculateAmount();
  }

  getSoonerTicketType(tts: TicketType[]) {
    const sorted = tts
      .filter((x) => x.start_on)
      .sort((a, b) => moment(a.start_on).diff(b.start_on));
    return sorted[0];
  }

  calculateAmount() {
    let usedTickets = 0;

    this.totalAmount = this.selectedTicketTypeList.reduce(
      (total: number, te: TicketType) => {
        const ut = te.tickets.filter(
          (t) => t.status === TicketStatus.USED,
        ).length;

        usedTickets += ut;

        return total + te.price * ut;
      },
      0,
    );

    this.selectedTicketsQuantity = usedTickets;
  }

  get disabledToCheckout() {
    if (this.event?.free) {
      return this.selectedTicketsQuantity === 0;
    } else {
      const freeTicketTypes = this.selectedTicketTypeList.filter(
        (ticketType) => ticketType.isFree || ticketType.price === 0,
      );
      if (freeTicketTypes.length) {
        for (let i in freeTicketTypes) {
          const freeTicketType = freeTicketTypes[i];
          const freeTickets = freeTicketType.tickets.filter(
            (ticket) => ticket.status === TicketStatus.USED,
          );
          if (freeTickets.length) {
            return false;
          }
        }
      }
    }

    return this.totalAmount === 0;
  }

  async proceedToCheckout(): Promise<void> {
    this.proceeding = true;

    if (this.event == null) return;

    this.selectedTicketTypeList = this.selectedTicketTypeList.filter(
      (ticketType) => {
        return (
          ticketType.tickets.filter(
            (ticket) => ticket.status === TicketStatus.USED,
          ).length > 0
        );
      },
    );

    const _ticketTypes: TicketType[] = [];

    const sessionParam = sessionStorage.getItem(Constants.Keys.Promoter);

    let promoterSlug = sessionParam ? JSON.parse(sessionParam) : '';

    promoterSlug =
      promoterSlug && promoterSlug.event == this.event.id
        ? promoterSlug.promoter
        : null;

    const order: Order = {
      id: 0,
      buyerEmail: '',
      buyerName: '',
      event: this.event,
      tickets: _ticketTypes,
      ticketIds: [],
      insuranceFee: 0,
      serviceFee: 0,
      discounted: 0,
      subTotal: this.totalAmount,
      totalToPay: this.totalAmount,
      slug: '',
      free_event: this.event.free,
      promoter: promoterSlug,
    };

    const ticketsIdsValidation = this.prepareTicketSToVerify();

    this.ticketsService
      .validateTicketsAvailability(this.event?.id ?? 0, ticketsIdsValidation)
      .subscribe({
        next: (value) => {
          if (value != null) {
            this.setAvailableTickets(value, order);
            this.router.navigate([`/order/checkout/${this.event?.slug}`]);
          } else {
            alert('Algunos tickets ya fueron seleccionados.');
            this.onInit();
          }
        },
        error: (err) => {
          alert('Algunos tickets ya fueron seleccionados.');
          this.onInit();
        },
      });
  }

  private prepareTicketSToVerify(): SendTicketVerificationDto[] {
    const ticketsIdsValidation: SendTicketVerificationDto[] = [];

    for (let i = 0; i < this.selectedTicketTypeList.length; i++) {
      const _ticketType: TicketType = JSON.parse(
        JSON.stringify(this.selectedTicketTypeList[i]),
      );

      ticketsIdsValidation.push({
        id: _ticketType.id,
        isSeat: _ticketType.isSeat,
        isFree: _ticketType.isFree,
        tickets: [],
      });

      for (let x = 0; x < _ticketType.tickets.length; x++) {
        const _ticket: Ticket = _ticketType.tickets[x];

        if (_ticket.status === TicketStatus.USED)
          ticketsIdsValidation[i].tickets.push(_ticket.id);
      }
    }

    return ticketsIdsValidation;
  }

  private setAvailableTickets(tickets: TicketVerificationDto[], order: Order) {
    const selectedTMP = JSON.parse(
      JSON.stringify(this.selectedTicketTypeList),
    ) as typeof this.selectedTicketTypeList;

    selectedTMP.forEach((t) => {
      const availableTt = tickets.find((tt) => tt.id == t.id)!;

      t.tickets = availableTt.tickets;

      order.tickets.push(t);
    });

    tickets.forEach((ticketType) => {
      order.ticketIds = order.ticketIds.concat(
        ticketType.tickets.map((t) => t.id),
      );
    });

    this.storageService.set(Constants.Keys.EventOrder, JSON.stringify(order));
  }

  getMapEventImageUrl(mapImage: string): string {
    return this.utilService.getAbsoluteUrl(mapImage);
  }

  private getBanners(): Banner[] {
    const banners = this.event?.cover.map((cover) => {
      return {
        imageUri: cover.attributes.url ?? '',
        active: true,
        description: null,
        endAt: null,
        id: 0,
        link: null,
        priority: 0,
        startAt: null,
        title: '',
      };
    });

    return banners as Banner[];
  }

  openMap() {
    new Fancybox([
      {
        src: this.getMapEventImageUrl(this.event?.mapImageUri ?? ''),
      },
    ]);
  }
}
