import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { routes as eventsRoutes } from './features/events/events.routing';
import { routes as contentsRoutes } from './features/content/content.routing';
import { PrimaryOutletComponent } from './core/outlets/primary.outlet/primary.outlet.component';
import { CheckoutOutletComponent } from './core/outlets/checkout.outlet/checkout.outlet.component';
import { CheckoutComponent } from './features/events/pages/checkout/checkout.component';
import { ConfirmationComponent } from './features/events/pages/confirmation/confirmation.component';
import { OrderDetailComponent } from './features/order/pages/order-detail/order-detail.component';
import { OrderTicketsComponent } from './features/order/pages/order-tickets/order-tickets.component';
import { ChallengeCompletedComponent } from './features/events/pages/checkout/challenge-completed/challenge-completed.component';

const routes: Routes = [
  {
    path: '',
    component: PrimaryOutletComponent,
    children: [
      ...eventsRoutes,
      ...contentsRoutes,
      { path: 'threeDSNotification', component: ChallengeCompletedComponent },
    ],
  },
  {
    path: 'order',
    component: CheckoutOutletComponent,
    children: [
      { path: 'checkout/:slug', component: CheckoutComponent },
      { path: ':order_slug/confirmation', component: ConfirmationComponent },
      { path: ':order_slug/details', component: OrderDetailComponent },
      { path: ':order_slug/tickets', component: OrderTicketsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
