import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './pages/contact/contact.component';
import { PriceComponent } from './pages/price/price.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { EventPublishComponent } from './pages/event-publish/event-publish.component';
import { OrderInsuranceComponent } from './pages/order-insurance/order-insurance.component';
import { FaqComponent } from './pages/faq/faq.component';
import { UsageTermsComponent } from './pages/usage-terms/usage-terms.component';
import { ReturnPoliticsComponent } from './pages/return-politics/return-politics.component';
import { BrandComponent } from './pages/brand/brand.component';
import { EventsModule } from '../events/events.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../../core/core.module';

@NgModule({
  declarations: [
    ContactComponent,
    PriceComponent,
    AboutUsComponent,
    EventPublishComponent,
    OrderInsuranceComponent,
    FaqComponent,
    UsageTermsComponent,
    ReturnPoliticsComponent,
    BrandComponent,
  ],
  imports: [CommonModule, EventsModule, ReactiveFormsModule, CoreModule],
})
export class ContentModule {}
