import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  private readonly _socketInstance: Socket;
  constructor() {
    this._socketInstance = io(environment.baseUrl, {
      transports: ['websocket'],
    });
  }

  getAbsoluteUrl(url: string): string {
    return url.indexOf('http') !== -1 ? url : `${environment.baseUrl}${url}`;
  }

  getSocket(): Socket {
    return this._socketInstance;
  }
}
