import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import TicketType from '../../../../core/models/ticket.type';
import { TicketStatus } from '../../../../core/models/ticket.status';
import Ticket from '../../../../core/models/ticket';
import { ResponseTicketTypeOrGroupedByDate } from '../../services/ticket.service';

interface ParsedTicketType extends TicketType {
  holded_tickets: Ticket[];
}

@Component({
  selector: 'event-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css'],
})
export class TicketComponent implements OnChanges {
  public groupedByDate = false;

  @Input()
  public eventMapImgSrc: string = '';

  @Input()
  public ticketTypeList: ResponseTicketTypeOrGroupedByDate = [];
  public groupedByDateParsed: { [day: string]: ParsedTicketType[] } = {};
  public days: string[] = [];
  parsedTicketTypeList: ParsedTicketType[] = [];

  @Output()
  onTicketChangeEvent: EventEmitter<TicketType> =
    new EventEmitter<TicketType>();

  ticketsUsed = 0;

  ngOnChanges() {
    if (!this.ticketTypeList) return;

    const listTMP: any = JSON.parse(JSON.stringify(this.ticketTypeList));

    this.days = Object.keys(this.ticketTypeList);

    if (!Array.isArray(this.ticketTypeList)) {
      this.groupedByDateParsed = this.ticketTypeList as any;

      for (const day in this.ticketTypeList) {
        this.groupedByDateParsed[day] = listTMP[day].map((tt: any) => {
          const availableTickets: Ticket[] = [];
          const holdedTickets: Ticket[] = [];

          tt.tickets.forEach((t: any) => {
            switch (t.status) {
              case TicketStatus.USED:
              case TicketStatus.AVAILABLE:
                availableTickets.push(t);
                break;

              case TicketStatus.ON_HOLD:
                holdedTickets.push(t);
                break;
            }
          });

          return {
            ...tt,
            tickets: availableTickets,
            holded_tickets: holdedTickets,
          };
        });
      }
    }
    if (!this.groupedByDate) {
      this.parsedTicketTypeList = listTMP.map((tt: any) => {
        const availableTickets: Ticket[] = [];
        const holdedTickets: Ticket[] = [];

        tt.tickets.forEach((t: any) => {
          switch (t.status) {
            case TicketStatus.USED:
            case TicketStatus.AVAILABLE:
              availableTickets.push(t);
              break;

            case TicketStatus.ON_HOLD:
              holdedTickets.push(t);
              break;
          }
        });

        return {
          ...tt,
          tickets: availableTickets,
          holded_tickets: holdedTickets,
        };
      });
    }
  }

  getGroupedByDayData(day: string) {
    const data = this.groupedByDateParsed;

    if (Array.isArray(data)) {
      return [];
    }

    return data[day];
  }

  /**
   * Returns true if the [ticketType] param is NOT sold and is of type seat
   * @param ticketType
   * @return boolean
   */
  isSeat(ticketType: ParsedTicketType): boolean {
    return !this.isUnavailable(ticketType) && ticketType.isSeat;
  }

  /**
   * Returns true if the [ticketType] param is NOT sold and is of type selector
   * @param ticketType
   * @return boolean
   */
  isSelector(ticketType: ParsedTicketType): boolean {
    return !this.isUnavailable(ticketType) && !ticketType.isSeat;
  }

  /**
   * Returns true if the [ticketType] param is sold
   * @param ticketType
   * @return boolean
   */
  isUnavailable(ticketType: ParsedTicketType) {
    const soldOut = this.isSoldOut(ticketType);
    const holdedOut = this.isHoldedOut(ticketType);

    return soldOut || holdedOut;
  }

  isSoldOut(ticketType: ParsedTicketType) {
    return (
      ticketType.tickets.length == 0 && ticketType.holded_tickets.length == 0
    );
  }

  isHoldedOut(ticketType: ParsedTicketType) {
    return (
      ticketType.tickets.length == 0 && ticketType.holded_tickets.length > 0
    );
  }

  sumUsed() {
    this.ticketsUsed += 1;
  }

  substractUsed() {
    this.ticketsUsed -= 1;
  }

  isGroupedByDate() {
    return !Array.isArray(this.ticketTypeList);
  }

  protected readonly TicketStatus = TicketStatus;
}
