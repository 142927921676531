import {
  AfterViewChecked,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import EventModel from '../../../models/event';
import { environment } from '../../../../../environments/environment';
import { DashboardService } from '../../../../features/events/services/dashboard.service';
import { Router } from '@angular/router';

interface HeaderItem {
  label: string;
  slug?: string;
  href?: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements AfterViewChecked {
  @ViewChild('searchInput') searchInput!: ElementRef;

  public items: HeaderItem[] = [];

  showSearch = false;
  searchCriteria = '';
  showNav = false;

  public events: EventModel[] = [];
  private get _events() {
    return this.dashboardService._events;
  }

  get searchIconName() {
    let iconName = 'search';

    if (this.showSearch) iconName = 'close';

    return iconName;
  }

  constructor(
    private dashboardService: DashboardService,
    private router: Router,
  ) {
    this.items = [
      { label: 'Eventos', slug: 'events/all' },
      { label: 'Precios', slug: 'precios' },
      { label: 'Publicar Evento', href: `${environment.vendorsUrl}/register` },
      { label: 'Contacto', slug: 'contacto' },
    ];
  }

  ngAfterViewChecked(): void {
    if (this.showSearch) this.searchInput.nativeElement.focus();
  }

  toggleSearch() {
    if (this.showNav) this.toggleNav();

    this.showSearch = !this.showSearch;
  }

  toggleNav() {
    if (this.showSearch) this.toggleSearch();

    this.showNav = !this.showNav;

    // document.getElementsByTagName('body')[0].classList.toggle('overflow-hidden')
  }

  onSearchEvent($event: Event) {
    const target = $event.target as HTMLInputElement;

    if (target.value.trim().length > 0) {
      const eventsTMP = this._events;

      const filteredEvents = eventsTMP.filter((event: EventModel) =>
        event.name
          .toLowerCase()
          .trim()
          .includes(target.value.toLowerCase().trim()),
      );

      if (filteredEvents.length >= 6) filteredEvents.splice(5);

      this.events = filteredEvents;
    } else {
      this.events = [];
    }
  }

  closeSearch() {
    this.searchCriteria = '';
    this.showSearch = false;
  }

  protected readonly environment = environment;

  goTo(item: HeaderItem) {
    if (item.slug) {
      this.router.navigate([item.slug]);
    } else if (item.href) {
      window.location.href = item.href;
    }

    this.showNav = false;
  }

  goToHome() {
    this.router.navigate(['/']);
  }
}
